import {IInitApp, ISetInitAppLoading, ISetIsAdmin} from "./types";
import {INIT_APP, SET_INIT_APP_LOADING, SET_IS_ADMIN} from "../types";
import {ISetIsAuth} from "../../auth/actions/types";

export const initApp: IInitApp = () => ({
    type: INIT_APP
})

export const setInitAppLoading: ISetInitAppLoading = payload => ({
    type: SET_INIT_APP_LOADING,
    payload
})

export const setIsAdmin: ISetIsAdmin = payload => ({
    type: SET_IS_ADMIN,
    payload
})