import React, {useState} from "react";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import {IUserObject} from "../../../../../redux/reducers/adminUsers/types.data";
import {Fade, IconButton} from "@material-ui/core";
import {userObjectListFlexes} from "../../../../../helpers/flexesHelpers";
import {EditObjectModalContainer} from "../../../Modals/EditObjectModal";
import {TSelDevicePart} from "../../../../../redux/api/requests/admin/type.data";
import {IIudDeviceContractor} from "../../../../../redux/reducers/adminUsers/actions/types";

const s = require('./style.module.scss');

export interface UserObjectListItemProps {
    userObject: IUserObject
    index: number
    userId: number
    searchPayload: TSelDevicePart
    iudDeviceContractorAsync: IIudDeviceContractor

    [key: string]: any
}

export const UserObjectListItem: React.FC<UserObjectListItemProps> = ({
                                                                          userObject,
                                                                          index,
                                                                          userId,
                                                                          searchPayload,
                                                                          iudDeviceContractorAsync,
                                                                          ...props
                                                                      }) => {
    const {
        idContractor, idContractorPart, idPart,
        namePart, nameContractor, nameDevice,
        idSystem, numberDevice, idDevice,
        nameSystem, aliasDevice
    } = userObject

    const [editObjectModal, setEditObjectModal] = useState<boolean>(false)

    const removeDeviceHandler = () => {
        iudDeviceContractorAsync({
            flag: "D",
            idUser: userId,
            idPart,
        }, () => null, searchPayload)
    }
    return (
        <Fade in timeout={{enter: 50 * index}}>
            <div className={s.user_object}>
                <div style={{flex: `0 0 ${userObjectListFlexes[0]}%`}}>{nameDevice}</div>
                <div style={{flex: `0 0 ${userObjectListFlexes[1]}%`}}>{nameSystem}</div>
                <div style={{flex: `0 0 ${userObjectListFlexes[2]}%`}}>{numberDevice}</div>
                <div style={{flex: `0 0 ${userObjectListFlexes[3]}%`}}>{namePart}</div>
                <div style={{flex: `0 0 ${userObjectListFlexes[4]}%`}}>{aliasDevice}</div>
                <div style={{flex: `0 0 ${userObjectListFlexes[5]}%`}}
                     className={s.row}>
                    <IconButton onClick={() => setEditObjectModal(true)}>
                        <EditIcon/>
                    </IconButton>
                    <IconButton onClick={removeDeviceHandler}>
                        <DeleteIcon/>
                    </IconButton>
                </div>
                <EditObjectModalContainer open={editObjectModal}
                                          closeHandler={() => setEditObjectModal(false)}
                                          object={userObject}
                                          userId={userId}
                                          searchPayload={searchPayload}
                />
            </div>
        </Fade>
    )
}