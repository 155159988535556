import React, { createRef, memo, useState } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import { IconButton } from "@material-ui/core";
import clsx from "clsx";
import { IRemoteControllerLargeProps } from "./types";
import { EditPseudonymModalContainer } from "../Modals/EditPseudonym";
import { ControllerCardModalContainer } from "../Modals/ControllerCardModal";
import { Tooltip } from "antd";
import { RemoteControllerHoverInfo } from "../Modals/RemoteControllerHoverInfo";


const s = require('./style.module.scss');


export const RemoteControllerLarge: React.FC<IRemoteControllerLargeProps> = memo(({
    controller,
    isLastInGroup,
    isFirstInGroup,
    remoteControllersHeader,
    setSelectedRemoteController,
    controllersGroupIndex
}) => {

    const isMobile: boolean = window.innerWidth < 750

    const classNames = clsx(
        s.item_wrapper,
        isFirstInGroup && controllersGroupIndex !== 0
            ? s.item_first
            : isFirstInGroup && s.item_first_noMargin,
        isLastInGroup && s.item_last
    )
    const {
        accountNumberSite, adressSite, aliasDevice,
        idDevice, idPart, test, nameDevice,
        namePart, connection, status,
    } = controller

    const editPseudonymRef = createRef<any>()
    const [editPseudonymModal, setEditPseudonymModal] = useState<boolean>(false)
    const [controllerCardModal, setControllerCardModal] = useState<boolean>(false)
    const [tooltipVisible, setTooltipVisible] = useState<boolean>(false)

    return (
        <Tooltip overlayInnerStyle={{ width: '250px' }}
            mouseLeaveDelay={0}
            mouseEnterDelay={0}
            destroyTooltipOnHide
            overlay={() => <RemoteControllerHoverInfo controller={controller} />}
            visible={tooltipVisible}
        >
            <div className={classNames}>
                <div className={s.item_content} onClick={(event) => {
                    if (editPseudonymRef.current?.contains(event.target)) {
                        return
                    }
                    setSelectedRemoteController(controller)
                    setControllerCardModal(true)
                }}
                    onMouseOver={() => !isMobile && setTooltipVisible(true)}
                    onMouseLeave={() => setTooltipVisible(false)}

                >
                    <div className={s.item_header}>

                        {remoteControllersHeader === 'number' && <div className={s.number}>{accountNumberSite}</div>}
                        {remoteControllersHeader === 'name' && <div className={s.name}>{nameDevice}</div>}
                        {remoteControllersHeader === 'number' && <div className={s.group_number}>гр. {namePart}</div>}

                    </div>
                    <div className={s.status} style={{ background: `#${status?.color || 'fff'}` }}>{status?.isStateArmSite || '-'}</div>
                    <div className={s.item_footer}>
                        <IconButton ref={editPseudonymRef}
                            className={s.btn_edit}
                            onClick={() => setEditPseudonymModal(true)}>
                            <EditIcon />
                        </IconButton>
                        <div className={s.nickname}>{aliasDevice}</div>
                    </div>
                </div>

                <EditPseudonymModalContainer open={editPseudonymModal}
                    closeHandler={() => setEditPseudonymModal(false)}
                    pseudonymValue={aliasDevice}
                    controllerId={idDevice}
                />

                <ControllerCardModalContainer open={controllerCardModal}
                    closeHandler={() => setControllerCardModal(false)}
                />
            </div>
        </Tooltip>
    );
});

