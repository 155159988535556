import React, {useState} from "react";
import {Modal} from "../../../common/Modal/Modal";
import {IModal} from "../../../common/Modal/types";
import {SimpleInput} from "../../../common/Fields/SimpleInput/SimpleInput";
import {SimpleButton} from "../../../common/Buttons/SimpleButton";
import {TRootState} from "../../../../redux/store";
import {IRequestEditControllerAlias} from "../../../../redux/reducers/userPanel/remoteControllers/actions/types";
import {
    editControllerAliasAsync
} from "../../../../redux/reducers/userPanel/remoteControllers/actions";
import {connect} from "react-redux";

const s = require('./style.module.scss')

interface IEditPseudonymProps extends IModal {
    pseudonymValue: string
    controllerId: string
    editControllerAliasAsync: IRequestEditControllerAlias
}

const EditPseudonymModal: React.FC<IEditPseudonymProps> = ({
                                                               open,
                                                               closeHandler,
                                                               pseudonymValue,
                                                               controllerId,
                                                               editControllerAliasAsync,
                                                           }) => {
    const [pseudonym, setPseudonym] = useState<string>(pseudonymValue)
    return (
        <Modal open={open} closeHandler={closeHandler} title={'Редактировать псевдоним'}>
            <SimpleInput value={pseudonym}
                         onChange={e => setPseudonym(e.target.value)}
                         label={'Псевдоним'}
                         placeholder={'Введите псевдоним'}
            />
            <SimpleButton className={s.btn_bottom} onClick={() => {
                editControllerAliasAsync({
                    id: controllerId,
                    alias: pseudonym
                })
            }}>
                Сохранить
            </SimpleButton>
        </Modal>
    )
}

const mapStateToProps = (state: TRootState) => ({})
const mapDispatchToProps = {
    editControllerAliasAsync,
}

export const EditPseudonymModalContainer = connect(mapStateToProps, mapDispatchToProps)(EditPseudonymModal)