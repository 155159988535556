import {
    REMOTE_CONTROLLERS_ACTION,
    SET_GATEWAY_LIST,
    SET_GATEWAY_LIST_LOADING,
    SET_REMOTE_CONTROLLERS_HEADER,
    SET_REMOTE_CONTROLLERS_LIST,
    SET_REMOTE_CONTROLLERS_LIST_LOADING,
    SET_REMOTE_CONTROLLERS_SEARCH_VALUE,
    SET_REMOTE_CONTROLLERS_SIZE,
    SET_SELECTED_REMOTE_CONTROLLER,
    TRemoteControllersReducer
} from "./types";

const init: TRemoteControllersReducer = {
    selectedRemoteController: null,
    remoteControllers: [],
    remoteControllersLoading: false,
    remoteControllersSize: "lg",
    remoteControllersHeader: "number",
    searchValue: '',
    controllerGatewaysList: [],
    controllerGatewaysListLoading: false
}

export const remoteControllerReducer = (state = init, action: REMOTE_CONTROLLERS_ACTION) => {
    switch (action.type) {
        case SET_REMOTE_CONTROLLERS_LIST: {
            return {
                ...state,
                remoteControllers: action.payload
            }
        }
        case SET_REMOTE_CONTROLLERS_LIST_LOADING: {
            return {
                ...state,
                remoteControllersLoading: action.payload
            }
        }
        case SET_SELECTED_REMOTE_CONTROLLER: {
            return {
                ...state,
                selectedRemoteController: action.payload
            }
        }
        case SET_REMOTE_CONTROLLERS_SIZE: {
            return {
                ...state,
                remoteControllersSize: action.payload
            }
        }
        case SET_REMOTE_CONTROLLERS_HEADER: {
            return {
                ...state,
                remoteControllersHeader: action.payload
            }
        }
        case SET_REMOTE_CONTROLLERS_SEARCH_VALUE: {
            return {
                ...state,
                searchValue: action.payload
            }
        }
        case SET_GATEWAY_LIST: {
            return {
                ...state,
                controllerGatewaysList: action.payload
            }
        }
        case SET_GATEWAY_LIST_LOADING: {
            return {
                ...state,
                controllerGatewaysListLoading: action.payload
            }
        }
        default:
            return state
    }
}