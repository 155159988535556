import { CODES_ACTIONS, 
    SET_CODE_LIST, 
    SET_CODE_LIST_LOADING, 
    SET_TOTAL_CODE_COUNT, 
    TNumCodeReducer} from "./types"



const init: TNumCodeReducer = {
    codeList: [],
    codeListLoading: false,
    totalCount: 0
}

export const codesReducer = (state = init, action: CODES_ACTIONS) => {
    switch (action.type) {
        case SET_CODE_LIST: {
            return {
                ...state,
                codeList: action.payload
            }
        }
        case SET_CODE_LIST_LOADING: {
            return {
                ...state,
                codeListLoading: action.payload
            }
        }
        case SET_TOTAL_CODE_COUNT: {
            return {
                ...state,
                totalCount: action.payload
            }
        }
        default: 
            return state
    }
}