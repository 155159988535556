import React, {useState} from "react";
import {connect} from "react-redux";
import {TRootState} from "../../../redux/store";
import {Modal} from "../../common/Modal/Modal";
import {IModal} from "../../common/Modal/types";
import {SimpleInput} from "../../common/Fields/SimpleInput/SimpleInput";
import {SimpleButton} from "../../common/Buttons/SimpleButton";
import {openNotification} from "../../../utils/helpers";
import {TEnumItem} from "../../../redux/globalTypes";
import {IIudDeviceContractor} from "../../../redux/reducers/adminUsers/actions/types";
import {IUserObject} from "../../../redux/reducers/adminUsers/types.data";
import {SimpleSelect} from "../../common/Fields/MultipleSelect/SimpleSelect";
import {iudDeviceContractorAsync} from "../../../redux/reducers/adminUsers/actions";
import {TSelDevicePart} from "../../../redux/api/requests/admin/type.data";


const s = require('./style.module.scss');


interface IEditObjectModalProps extends IModal {
    systemsList: TEnumItem[],
    iudDeviceContractorAsync: IIudDeviceContractor
    object: IUserObject
    userId: number
    searchPayload: TSelDevicePart
}

const EditObjectModal: React.FC<IEditObjectModalProps> = ({
                                                              open,
                                                              closeHandler,
                                                              systemsList,
                                                              iudDeviceContractorAsync,
                                                              object,
                                                              userId,
                                                              searchPayload,
                                                          }) => {
    const {
        idContractor, idContractorPart, idPart,
        namePart, nameContractor, nameDevice,
        idSystem, numberDevice, idDevice,
        nameSystem, aliasDevice
    } = object

    const [nameDeviceLocal, setNameDevice] = useState<string>(aliasDevice || "")
    const [idSystemLocal, setIdSystem] = useState<string | number>(idSystem || 0)
    const [numberDeviceLocal, setNumberDevice] = useState<string>(numberDevice || "")
    const [namePartLocal, setNamePart] = useState<string>(namePart || "")

    const editDeviceContractorHandler = () => {
        if (!nameDevice || !idSystem || !numberDevice || !namePart) {
            openNotification({type: "warning", message: "Заполните все данные!"})
            return
        }

        iudDeviceContractorAsync({
                flag: "U",
                nameDevice: nameDeviceLocal,
                idSystem: idSystemLocal,
                numberDevice: numberDeviceLocal,
                namePart: namePartLocal,
                idUser: userId,
                idDevice,
                idPart,
            },
            () => null, searchPayload)
    }

    return (
        <Modal open={open} closeHandler={closeHandler} title={'andromeda_evromost 81810/0'}>
            <div className={s.simple_input_test}>
                <SimpleInput className={s.input_modal_item}
                             value={nameDeviceLocal}
                             onChange={e => setNameDevice(e.target.value)}
                             label={'Псевдоним'}
                             placeholder={'Укажите псевдоним'}
                />
                <SimpleSelect className={s.input_modal_item}
                              value={idSystemLocal}
                              onChange={setIdSystem}
                              label={'Панель'}
                              placeholder={'Выберите панель'}
                              items={systemsList}
                />
                <SimpleInput className={s.input_modal_item}
                             value={numberDeviceLocal}
                             onChange={e => setNumberDevice(e.target.value)}
                             label={'Пультовый номер'}
                             placeholder={'Укажите пультовый номер'}
                />
                <SimpleInput className={s.input_modal_item}
                             value={namePartLocal}
                             onChange={e => setNamePart(e.target.value)}
                             label={'Группа'}
                             placeholder={'Укажите группу'}
                />
            </div>
            <SimpleButton className={s.btn_bottom} onClick={editDeviceContractorHandler}>
                Сохранить
            </SimpleButton>
        </Modal>

    )
}
const mapStateToProps = (state: TRootState) => ({
    systemsList: state.adminUsers.systemsList
})
const mapDispatchToProps = {
    iudDeviceContractorAsync
}

export const EditObjectModalContainer = connect(mapStateToProps, mapDispatchToProps)(EditObjectModal)