export const paths = {
    baseUrl: 'https://api.neva-online.ru',
    login: '/Account/Login',
    sendSms: '/Account/SendSms',
    loginSms: '/Account/LoginSms',
    refreshLogin: '/Account/RefreshToken',
    addOrChangeUser: '/Account/AddOrChangeUser',
    registerUser: '/Account/RegisterUser',
    unRegisterUser: '/Account/UnRegisterUser',

    selCurrentContractor: '/api/data/sel_current_contractor',

    selSites: '/api/data/sel_sites',
    iudSites: '/api/data/iud_sites',
    selZones: '/api/data/sel_zones',
    selEvents: '/api/data/sel_events',

    selContractor: '/api/data/sel_contractor',
    iudContractor: '/api/data/iud_contractor',
    selVisibleCode: '/api/data/sel_visible_code',
    iudVisibleCode: '/api/data/iud_visible_code',
    selObj: '/api/data/sel_obj',

    selDevicePart: '/api/data/sel_device_part', // список объектов пользователя
    iudDeviceContractor: '/api/data/iud_device_contractor', // добавление групп и объектов для контрагента
    selSystems: '/api/data/sel_systems', // выборка ПЦО/панелей для combobox при iu объекта пользователя
    selLogs: '/api/data/sel_logs', //выборка логов
}