import {IUser, IUserObject} from "./types.data";
import {TEnumItem} from "../../globalTypes";

export type TUserListReducer = {
    // пользователи
    userList: IUser[]
    userListLoading: boolean
    selectedUser: IUser | null
    totalCount: number
    iudUserLoading: boolean
    // объекты пользователя
    userObjectsList: IUserObject[]
    userObjectsListLoading: boolean
    totalUserObjectListCount: number
    iudUserObjectLoading: boolean
    // панели
    systemsList: TEnumItem[]
    systemsListLoading: boolean
}
export const SET_USER_LIST = 'SET_USER_LIST'
export const SET_USER_LIST_LOADING = 'SET_USER_LIST_LOADING'
export const SET_SELECTED_USER = 'SELECTED_USER'
export const SET_TOTAL_USER_LIST_COUNT = 'SET_TOTAL_USER_LIST_COUNT'
export const SET_IUD_USER_LOADING = 'SET_IUD_USER_LOADING'

export const SET_USER_OBJECT_LIST = 'USER_OBJECT_LIST'
export const SET_USER_OBJECT_LIST_LOADING = 'SET_USER_OBJECT_LIST_LOADING'
export const SET_TOTAL_USER_OBJECT_LIST_COUNT = 'SET_TOTAL_USER_OBJECT_LIST_COUNT'
export const SET_IUD_USER_OBJECT_LOADING = 'SET_IUD_USER_OBJECT_LOADING'

export const SET_SYSTEMS_LIST = 'SET_SYSTEMS_LIST'
export const SET_SYSTEMS_LIST_LOADING = 'SET_SYSTEMS_LIST_LOADING'


export type SET_USER_LIST_ACTION = {
    type: typeof SET_USER_LIST,
    payload: IUser[]
}
export type SET_USER_LIST_LOADING_ACTION = {
    type: typeof SET_USER_LIST_LOADING,
    payload: boolean
}
export type SET_SELECTED_USER_ACTION = {
    type: typeof SET_SELECTED_USER,
    payload: IUser | null
}
export type SET_TOTAL_USER_LIST_COUNT_ACTION = {
    type: typeof SET_TOTAL_USER_LIST_COUNT,
    payload: number
}
export type SET_IUD_USER_LOADING_ACTION = {
    type: typeof SET_IUD_USER_LOADING,
    payload: boolean
}

export type SET_USER_OBJECT_LIST_ACTION = {
    type: typeof SET_USER_OBJECT_LIST,
    payload: IUserObject[]
}
export type SET_USER_OBJECT_LIST_LOADING_ACTION ={
    type: typeof SET_USER_OBJECT_LIST_LOADING,
    payload: boolean
}
export type SET_TOTAL_USER_OBJECT_LIST_COUNT_ACTION = {
    type: typeof SET_TOTAL_USER_OBJECT_LIST_COUNT,
    payload: number
}
export type SET_IUD_USER_OBJECT_LOADING_ACTION = {
    type: typeof SET_IUD_USER_OBJECT_LOADING,
    payload: boolean
}

export type SET_SYSTEMS_LIST_ACTION = {
    type: typeof SET_SYSTEMS_LIST,
    payload: TEnumItem[]
}
export type SET_SYSTEMS_LIST_LOADING_ACTION = {
    type: typeof SET_SYSTEMS_LIST_LOADING,
    payload: boolean
}

export type USER_LIST_ACTIONS = 
    | SET_USER_LIST_ACTION
    | SET_USER_LIST_LOADING_ACTION
    | SET_SELECTED_USER_ACTION
    | SET_USER_OBJECT_LIST_ACTION
    | SET_TOTAL_USER_LIST_COUNT_ACTION
    | SET_USER_OBJECT_LIST_LOADING_ACTION
    | SET_TOTAL_USER_OBJECT_LIST_COUNT_ACTION
    | SET_IUD_USER_LOADING_ACTION
    | SET_SYSTEMS_LIST_ACTION
    | SET_SYSTEMS_LIST_LOADING_ACTION
    | SET_IUD_USER_OBJECT_LOADING_ACTION