import {
    ISetFilterObjectList,
    ISetFilterObjectListLoading,
    ISetReportsList,
    ISetReportsListLoading,
    ISetTotalReportsCount
} from "./types";
import {
    SET_FILTER_OBJECT_LIST,
    SET_FILTER_OBJECT_LIST_LOADING,
    SET_REPORTS_LIST,
    SET_REPORTS_LIST_LOADING,
    SET_TOTAL_REPORTS_COUNT
} from "../types";

export const setReportsList: ISetReportsList = payload => ({
    type: SET_REPORTS_LIST,
    payload
})
export const setReportsListLoading: ISetReportsListLoading = payload => ({
    type: SET_REPORTS_LIST_LOADING,
    payload
})
export const setTotalReportsCount: ISetTotalReportsCount = payload => ({
    type: SET_TOTAL_REPORTS_COUNT,
    payload
})
export const setFilterObjectList: ISetFilterObjectList = payload => ({
    type: SET_FILTER_OBJECT_LIST,
    payload
})
export const setFilterObjectListLoading: ISetFilterObjectListLoading = payload => ({
    type: SET_FILTER_OBJECT_LIST_LOADING,
    payload
})