import React, {createRef, useEffect} from 'react';
import {Fade} from "@material-ui/core";
import {IEvent, IReport} from "../../../redux/reducers/reports/types.data";
import {formatISOToDateString, formatISOToTimeString} from "../../../helpers/dateHelpers";
import {reportsListFlexes} from "../../../helpers/flexesHelpers";
import {setPriorityFeedColor} from "../../UserPanel/InformationFeed/helpers";
import {alarm} from "../../../static/audio/audio";
import { isMobile } from '../../../helpers/mobileHelpers';

const s = require('./style.module.scss')

interface IColumnComponentProps {
    report: IEvent
    index: number

    [key: string]: any
}

export const Report: React.FC<IColumnComponentProps> = ({
                                                            report,
                                                            index,
                                                            ...props
                                                        }) => {
    const {color, siteName, siteNum, textEvent, typeEvent, date, isAlarm, textColor} = report
                  
    return (
        <Fade in timeout={{enter: 50 * index}}>
            {isMobile ? 
            <div className={s.mobileReportContainer}>

            <div className={s.mobileReport} style={{background: color ? '#' + color : '#fff', color: `#${textColor || '000'}`}}>
            {date && formatISOToDateString(date) || '-'}, {date && formatISOToTimeString(date) || '-'}<br/>
            {siteName}<br/>
            <span>Пультовый номер: </span>{siteNum}<br/>
            <span>Тип события: </span>{typeEvent}<br/>
            <span>Текст: </span>{textEvent}
            </div>
            </div>
            :
             <div className={s.report} style={{background: color ? '#' + color : '#fff', color: `#${textColor || '000'}`}}>
                <div className={s.report_date} style={{flex: `0 0 ${reportsListFlexes[0]}%`}}>
                    {date && formatISOToDateString(date) || '-'}, {date && formatISOToTimeString(date) || '-'}
                </div>
                <div style={{flex: `0 0 ${reportsListFlexes[1]}%`}}>{siteName}</div>
                <div style={{flex: `0 0 ${reportsListFlexes[2]}%`}}>{siteNum}</div>
                <div style={{flex: `0 0 ${reportsListFlexes[3]}%`}}>{typeEvent}</div>
                <div style={{flex: `0 0 ${reportsListFlexes[4]}%`}}>{textEvent}</div>
            </div>
            }
            
        </Fade>
    );
};


