import {adminPaths} from "./adminSwitch/paths";

export const ROUTE_PATHS = {
    login: '/login',
    main: (page?: number | string) => ({
        path: `/main/${page}`
    }),
    report: (page?: number | string) => ({
        path: `/report/${page}`
    }),
    workers: '/workers',
    admin: {...adminPaths},
}