import {
    ISetGatewayList, ISetGatewayListLoading,
    ISetRemoteControllers, ISetRemoteControllersHeader,
    ISetRemoteControllersLoading,
    ISetRemoteControllersSearchValue,
    ISetRemoteControllersSize,
    ISetSelectedRemoteController
} from "./types"

import {
    SET_GATEWAY_LIST, SET_GATEWAY_LIST_LOADING,
    SET_REMOTE_CONTROLLERS_HEADER,
    SET_REMOTE_CONTROLLERS_LIST,
    SET_REMOTE_CONTROLLERS_LIST_LOADING,
    SET_REMOTE_CONTROLLERS_SEARCH_VALUE,
    SET_REMOTE_CONTROLLERS_SIZE,
    SET_SELECTED_REMOTE_CONTROLLER
} from "../types";

export const setRemoteControllers: ISetRemoteControllers = payload => ({
    type: SET_REMOTE_CONTROLLERS_LIST,
    payload
})
export const setRemoteControllersLoading: ISetRemoteControllersLoading = payload => ({
    type: SET_REMOTE_CONTROLLERS_LIST_LOADING,
    payload
})

export const setSelectedRemoteController: ISetSelectedRemoteController = payload => ({
    type: SET_SELECTED_REMOTE_CONTROLLER,
    payload
})

export const setRemoteControllersSize: ISetRemoteControllersSize = payload => ({
    type: SET_REMOTE_CONTROLLERS_SIZE,
    payload
})
export const setRemoteControllersHeader: ISetRemoteControllersHeader =  payload => ({
    type: SET_REMOTE_CONTROLLERS_HEADER,
    payload
})

export const setRemoteControllersSearchValue: ISetRemoteControllersSearchValue =  payload => ({
    type: SET_REMOTE_CONTROLLERS_SEARCH_VALUE,
    payload
})

export const setGatewayList: ISetGatewayList = payload => ({
    type: SET_GATEWAY_LIST,
    payload
})
export const setGatewayListLoading: ISetGatewayListLoading = payload => ({
    type: SET_GATEWAY_LIST_LOADING,
    payload
})