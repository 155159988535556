import {setInitAppLoading} from "./actions";
import {getUserInfoAsync} from "../../user/actions";
import {instance} from "../../../api";
import {selSystemsAsync} from "../../adminUsers/actions";


function loginRedirect() {
    if (!window.location.pathname.includes('/login') && window.location.pathname.toString() !== '/admin') {
        window.location.pathname = '/login'
    }
}

export const initAppAsync = () => async (dispatch: any) => {
    try {
        const res = await instance().close().refresh()
        const hasToken = res?.data?.token;
        if (!hasToken) {
            loginRedirect();
            return
        }

        await dispatch(setInitAppLoading(true))
        await dispatch(getUserInfoAsync())
        await dispatch(selSystemsAsync())

    } catch (err) {
        loginRedirect();
    } finally {
        await dispatch(setInitAppLoading(false))
    }
}