import React from "react";
import {IModalProps} from "./types";
import {Dialog} from "@material-ui/core";
import {ModalHeader} from "./ModalHeader";
import {Loader} from "../Loader/Loader";
import clsx from "clsx";

const s = require('./style.module.scss')
export const Modal: React.FC<IModalProps> = ({
                                                 open,
                                                 closeHandler,
                                                 title,
                                                 loading,
                                                 children,
                                                 ...props
                                             }) => {
    return (
        <Dialog open={open} onClose={closeHandler} fullWidth={props?.fullWidth}
        style={{zIndex:100}}
        >
            <ModalHeader title={title} closeHandler={closeHandler}/>
            <div className={clsx(s.body, loading && s.body_loading)}>
                {loading
                    ? <Loader/>
                    : children}
            </div>
        </Dialog>
    )
}