import {IiudVisibleCode, IRequestCodeList} from './types';
import {setCodesList, setCodesListLoading} from "./actions";
import {instance} from "../../../api";
import {checkError} from "../../../../utils/helpers";


export const getCodesListAsync: IRequestCodeList = (payload) => async (dispatch:  any) => {
    await dispatch(setCodesListLoading(true))
    await instance().close().getVisibleCode(payload)
        .then(res => {
            dispatch(setCodesList(res.data || []))
        })
        .catch(err => {
            checkError(err)
        })
        .finally(() => {
            dispatch(setCodesListLoading(false))
        })
}

export const iudVisibleCodeAsync: IiudVisibleCode = payload => async (dispatch: any) => {
    await instance().close().iudVisibleCode(payload)
        .then(() => {
            dispatch(getCodesListAsync({}))
        })
        .catch(err => {
            checkError(err)
        })
}