import {IRequestUserInfo} from "./types";
import {setUserInfo, setUserInfoLoading} from "./actions";
import {instance} from "../../../api";
import {checkError} from "../../../../utils/helpers";

export const getUserInfoAsync: IRequestUserInfo = () => async (dispatch: any) => {
    await dispatch(setUserInfoLoading(true))
    await instance().close().selCurrentContractor()
        .then(res => {
            dispatch(setUserInfo({
                ...res.data,
            }))
        })
        .catch(err => {
            checkError(err)
        })
        .finally(() => {
            dispatch(setUserInfoLoading(false))
        })
}