import React from "react";
import {SET_CURRENT_PAGE, SIDEBAR_ACTIONS, TSidebarReducer} from "./types";
import {ADMIN_PAGES, CLIENT_PAGES} from "./types.data";
import {ROUTE_PATHS} from "../../../routers/paths.main";
import {TilesIcon} from "../../../icons/icon";
import {Edit, Settings} from "@material-ui/icons";
import DescriptionIcon from '@material-ui/icons/Description';
import ErrorIcon from '@material-ui/icons/Error';

const init: TSidebarReducer = {
    adminPages: [
        {id: ADMIN_PAGES.MAIN, name: 'Главная', url: ROUTE_PATHS.admin.users(1).path, icon: <TilesIcon/>},
        {id: ADMIN_PAGES.SETTINGS, name: 'Настройки', url: ROUTE_PATHS.admin.codes, icon: <Settings style={{color: '#fff'}}/>},
        {id: ADMIN_PAGES.LOG, name: 'Ошибки', url: ROUTE_PATHS.admin.logs(1).path, icon: <ErrorIcon style={{color: '#fff'}}/>}
    ],
    clientPages: [
        {id: CLIENT_PAGES.MAIN, name: 'Главная', url: ROUTE_PATHS.main(1).path, icon: <TilesIcon/>},
        {id: CLIENT_PAGES.REPORTS, name: 'Отчёты', url: ROUTE_PATHS.report(1).path, icon: <DescriptionIcon style={{color: '#fff'}}/>},
        // {id: CLIENT_PAGES.USERS, name: 'Ответственные', url: ROUTE_PATHS.workers, icon: <Edit style={{color: '#fff'}}/>}
    ],
    currentPage: {
        id: CLIENT_PAGES.MAIN,
        name: 'Главная',
        url: ROUTE_PATHS.main(1).path,
        icon: <TilesIcon/>,
    }
}

export const sidebarReducer = (state = init, action: SIDEBAR_ACTIONS) => {
    switch (action.type) {
        case SET_CURRENT_PAGE: {
            return {
                ...state,
                currentPage: action.payload,
            }
        }
        default:
            return state
    }
}