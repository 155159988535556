import React, { memo, useEffect, useRef, useState } from "react";
import { IUserObject } from "../../../../../redux/reducers/adminUsers/types.data";
import { connect } from "react-redux";
import { userObjectListFlexes } from "../../../../../helpers/flexesHelpers";
import clsx from "clsx";
import { Loader } from "../../../../common/Loader/Loader";
import { UserObjectListItem } from "./UserObjectListItem";
import { TRootState } from "../../../../../redux/store";
import { userObjectListHeaders } from "../../../../../helpers/headersHelpers";
import { TSelDevicePart } from "../../../../../redux/api/requests/admin/type.data";
import { iudDeviceContractorAsync } from "../../../../../redux/reducers/adminUsers/actions";
import { IIudDeviceContractor } from "../../../../../redux/reducers/adminUsers/actions/types";
import { SimpleButtonUp } from "../../../../common/SimpleButtonUp/SimpleButttonUp";
import { UserObjectListCardItem } from "./UserObjectListCardItem";


const s = require('./style.module.scss')

interface IUserObjectProps {
    objectsList: IUserObject[]
    objectsListLoading: boolean
    totalCount: number
    userId: string
    searchPayload: TSelDevicePart
    iudDeviceContractorAsync: IIudDeviceContractor
}

export const UserObjects: React.FC<IUserObjectProps> = memo(({
    objectsList,
    objectsListLoading,
    totalCount,
    userId,
    searchPayload,
    iudDeviceContractorAsync,
}) => {

    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768)

    const ref = useRef<HTMLDivElement>(null)

    const scrollTopHandler = () => {
        if (ref?.current) {
            if (ref.current.scrollTop > 0) {
                ref.current.scrollBy({
                    top: -ref.current?.clientHeight,
                    behavior: "smooth"
                });
            }
        }
    }

    return (
        <div className={s.objects} ref={ref}>
            {!isMobile && <div className={s.user_object_header}>
                {userObjectListHeaders.map((value, index) => <div
                    key={`user-object-header-${index}`}
                    style={{ flex: `0 0 ${userObjectListFlexes[index]}%` }}>
                    {value}</div>)}
            </div>}
            <div className={clsx(s.objects_body, objectsListLoading && s.objects_body_loading)}>
                {objectsListLoading
                    ? <Loader />
                    : objectsList && objectsList.length > 0 ? objectsList.map((value, index) => {
                        if (!isMobile) return <UserObjectListItem key={`user-object-${index}`}
                            index={index}
                            userObject={value}
                            userId={+userId}
                            searchPayload={searchPayload}
                            iudDeviceContractorAsync={iudDeviceContractorAsync}
                        />
                        else return <UserObjectListCardItem key={`user-object-${index}`}
                            index={index}
                            userObject={value}
                            userId={+userId}
                            searchPayload={searchPayload}
                            iudDeviceContractorAsync={iudDeviceContractorAsync}
                        />
                    }) : <div className={s.nodata}>Нет данных!</div>}
            </div>

            {!isMobile && <SimpleButtonUp onClick={scrollTopHandler} />}
        </div>
    )
})

const mapStateToProps = (state: TRootState) => ({
    objectsList: state.adminUsers.userObjectsList,
    objectsListLoading: state.adminUsers.userObjectsListLoading,
    totalCount: state.adminUsers.totalCount,
})
const mapDispatchToProps = {
    iudDeviceContractorAsync
}

export const UserObjectsContainer = connect(mapStateToProps, mapDispatchToProps)(UserObjects)
