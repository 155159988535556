import { connect } from "react-redux"
import { TRootState } from "../../redux/store"
import { SearchField } from '../common/SearchField/SearchField'
import { SimpleButton } from "../common/Buttons/SimpleButton"
import { IUser } from "../../redux/reducers/adminUsers/types.data"
import { IRequesUserList, ISetSelectedUser } from "../../redux/reducers/adminUsers/actions/types"
import { UserListItem } from './UserList/UserListItem'
import { PageTitle } from "../common/PageTitle/PageTitle";
import React, { useState, useEffect, FC } from "react";
import { searchLabel, searchPlaceholderAdmin } from "../../helpers/mockHelpers";
import { AddUserModalContainer } from "./Modals/AddUserModal"
import { getUserListAsync } from "../../redux/reducers/adminUsers/actions"
import { Loader } from "../common/Loader/Loader"
import { setSelectedUser } from "../../redux/reducers/adminUsers/actions/actions";
import { SimplePagination } from "../common/SimplePagination/Pagination";
import { useHistory, useRouteMatch } from "react-router-dom"
import { ROUTE_PATHS } from "../../routers/paths.main";
import { getInfoFeedListFromSocket } from "../../redux/reducers/userPanel/informationFeed/action";


const s = require('./style.module.scss')

interface IUserListProps {
    userList: IUser[]
    userListLoading: boolean
    totalCount: number
    getUserListAsync: IRequesUserList
    setSelectedUser: ISetSelectedUser
    getInfoFeedListFromSocket: any
}

const AdminUsers: FC<IUserListProps> = ({
    userList,
    userListLoading,
    totalCount,
    getUserListAsync,
    setSelectedUser,
    getInfoFeedListFromSocket,
}) => {
    // useEffect(() => {
    //     getInfoFeedListFromSocket()
    // }, [])
    const PAGE_SIZE = 10

    const history = useHistory()
    const match: any = useRouteMatch()

    const [addUserModal, setAddUserModal] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)

    useEffect(() => {
        setCurrentPage(+match?.params?.page)
    }, [match])

    useEffect(() => {
        getUserListAsync({
            offset: (+match?.params?.page - 1) * PAGE_SIZE,
            count: PAGE_SIZE,
        })
    }, [currentPage])

    return (
        <div className={s.admin_users_main_wrap}>
            <PageTitle title='Пользователи' />

            <div className={s.search_field_admin}>
                <SimpleButton className={s.search_field_clear}
                    size='medium'
                    onClick={() => setAddUserModal(true)}>
                    Добавить пользователя
                </SimpleButton>
            </div>

            <SearchField label={searchLabel}
                placeholder={searchPlaceholderAdmin}
                withClear
                onSearch={(value: string) => {
                    getUserListAsync({
                        offset: (+match?.params?.page - 1) * PAGE_SIZE,
                        count: PAGE_SIZE,
                        searchValue: value
                    })
                }}
            />

            <div className={s.users_list}>
                <div className={s.list_column_title}>Имя</div>
                <div className={s.list_column_title}>Номер телефона</div>
                <div className={s.list_column_title}></div>
            </div>
            <div className={s.users_list_items}>
                {userListLoading
                    ? <Loader />
                    : userList && userList?.map((value, index) => <UserListItem key={`user-${index}`}
                        index={index}
                        userListItem={value}
                        setSelectedUser={setSelectedUser}
                    />
                    )}
            </div>

            <SimplePagination total={totalCount}
                onChange={page => history.push(ROUTE_PATHS.admin.users(page).path)}
                pageSize={PAGE_SIZE}
                currentPage={currentPage}
                className={s.pagination}
            />


            <AddUserModalContainer open={addUserModal}
                closeHandler={() => setAddUserModal(false)}
                offset={(+match?.params?.page - 1) * PAGE_SIZE}
                count={PAGE_SIZE}
            />
        </div>
    )
}

const mapStateToProps = (state: TRootState) => ({
    userList: state.adminUsers.userList,
    userListLoading: state.adminUsers.userListLoading,
    totalCount: state.adminUsers.totalCount
})
const mapDispatchToProps = {
    getUserListAsync,
    setSelectedUser,
    getInfoFeedListFromSocket,
}

export const AdminUsersContainer = connect(mapStateToProps, mapDispatchToProps)(AdminUsers)