import React, { createRef, useEffect, useState } from "react";
import { ITopSideProps } from "./types";
import { TRootState } from "../../redux/store";
import { connect, useSelector } from "react-redux";
import AutorenewIcon from '@material-ui/icons/Autorenew';
import { AnimatedItem } from "../common/AnimatedItem/AnimatedItem";
import logo from '../../static/images/logo.png'
import { ArrowIcon } from "../../icons/icon";
import clsx from "clsx";
import { SimpleButton } from "../common/Buttons/SimpleButton";
import { keys } from "../../redux/api/keys";
import { useOnClickOutside } from "../../hooks/useClickOutSide";
import { ROUTE_PATHS } from "../../routers/paths.main";
import BurgerMenuIcon from "../../icons/burgerMenu";
import { ILeftSideProps } from './types'
import { Link } from "react-router-dom";
import { SimpleTooltip } from "../common/SimpleTooltip/SimpleTooltip";
import { FadeItem } from "../common/AnimatedItem/FadeAnim";

const s = require('./style.module.scss')

const TopSide: React.FC<ITopSideProps> = ({
    userInfo,
    userInfoLoading,
    isMobile,
}) => {
    const [openBurgerMenu, setOpenBurgerMenu] = useState<boolean>(false)

    const [disabledExit, setDisabledExit] = useState<boolean>(false)
    const [open, setOpen] = useState<boolean>(false)
    const [openLeft, setOpenLeft] = useState<boolean>(false)
    const exitRef = createRef<HTMLDivElement>()
    useOnClickOutside(exitRef, () => setOpen(false))



    return (
        <nav className={s.navbar}>
            <div className={s.logo}>
                <img src={logo} />
            </div>

            <div className={s.navbar_actions}>
                <div onClick={() => window.location.reload()}>
                    <AnimatedItem>
                        <AutorenewIcon style={{ color: '#fff', marginTop: '25%' }} />
                    </AnimatedItem>
                </div>
                <div className={s.user} style={{ color: '#fff' }}>{userInfo?.userName}</div>

                <div className={
                    clsx(open
                        ? s.sidebar_item_arrow_up
                        : s.sidebar_item_arrow_down)
                }
                    onClick={() => setOpen(!open)}>
                    <AnimatedItem>
                        <ArrowIcon />
                    </AnimatedItem>
                </div>

                {isMobile && <div className={s.burger} onClick={() => setOpenBurgerMenu(!openBurgerMenu)}>
                    <AnimatedItem>
                        <BurgerMenuIcon color={'white'} />
                    </AnimatedItem>
                </div>}

                <BurgerMenu open={openBurgerMenu} setOpen={setOpenBurgerMenu} />


                <div className={clsx(open
                    ? s.logout
                    : s.logout_hidden)}
                    ref={exitRef}
                >
                    <SimpleButton onClick={() => {
                        setDisabledExit(true)
                        localStorage.removeItem(keys.main)
                        window.location.pathname = userInfo?.isAdmin
                            ? ROUTE_PATHS.admin.path
                            : ROUTE_PATHS.login
                    }} disabled={disabledExit}>
                        Выйти
                    </SimpleButton>
                </div>
            </div>
        </nav>
    )
}

const mapStateToProps = (state: TRootState) => ({
    userInfo: state.user.userInfo,
    userInfoLoading: state.user.userInfoLoading,

})
const mapDispatchToProps = {}

export const TopSideContainer = connect(mapStateToProps, mapDispatchToProps)(TopSide)




interface IBurgerMenuProps {
    open: boolean
    setOpen: (value: boolean) => void
}

const BurgerMenu: React.FC<IBurgerMenuProps> = ({
    open,
    setOpen
}) => {

    const isAdmin = useSelector((state: TRootState) => state.user.userInfo?.isAdmin)

    const internalPagesAdmin = useSelector((state: TRootState) => state.sidebar.adminPages)
    const internalPagesClients = useSelector((state: TRootState) => state.sidebar.clientPages)

    const currentPage = useSelector((state: TRootState) => state.sidebar.currentPage)

    return <div className={open ? s.burgerMenu_open : s.burgerMenu}>
        {(isAdmin ? internalPagesAdmin : internalPagesClients).map(page => {
            return <Link to={page.url} className={s.sidebar_item}>
                <AnimatedItem onClick={() => setOpen(!open)}>
                    <SimpleTooltip
                        active={!open}
                        title={page.name}
                        placement={"right"}>
                        {page.icon}
                    </SimpleTooltip>
                </AnimatedItem>
                <FadeItem onClick={() => setOpen(!open)} active={open}>
                    {page.name}
                </FadeItem>
            </Link>
        })}
        {/* <div className={clsx(s.sidebar_item,
            s.sidebar_item_arrow,
            open && s.sidebar_item_arrow_open)}
            onClick={() => setOpen(!open)}>
            <AnimatedItem>
                <SimpleTooltip active={!open}
                    title={'Открыть меню'}
                    placement={"right"}>
                    <ArrowIcon />
                </SimpleTooltip>
            </AnimatedItem>
            <FadeItem active={open}>Скрыть меню</FadeItem>
        </div> */}
    </div>
}