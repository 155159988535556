import {IEvent, IReport} from '../../reports/types.data';

export type TInformationFeedReducer = {
    infoFeedList: IEvent[]
    infoFeedListLoading: boolean
    totalCount: number
    showTestEvents: boolean
    searchValue: string
}

export const SET_INFO_FEED_LIST = 'SET_INFO_FEED_LIST'
export const SET_INFO_FEED_LIST_LOADING = 'SET_INFO_FEED_LIST_LOADING'
export const SET_TOTAL_INFO_FEED_COUNT = 'SET_TOTAL_INFO_FEED_COUNT'
export const SET_SHOW_TEST_EVENTS = 'SET_SHOW_TEST_EVENTS'
export const SET_INFORMATION_FEED_SEARCH_VALUE = 'SET_INFORMATION_FEED_SEARCH_VALUE'

export type SET_INFO_FEED_LIST_ACTION = {
    type: typeof SET_INFO_FEED_LIST,
    payload: IEvent[]
}
export type SET_INFO_FEED_LIST_LOADING_ACTION = {
    type: typeof SET_INFO_FEED_LIST_LOADING,
    payload: boolean
}
export type SET_TOTAL_INFO_FEED_COUNT_ACTION = {
    type: typeof SET_TOTAL_INFO_FEED_COUNT
    payload: number
}
export type SET_SHOW_TEST_EVENTS_ACTION = {
    type: typeof SET_SHOW_TEST_EVENTS,
    payload: boolean
}
export type SET_INFORMATION_FEED_SEARCH_VALUE_ACTION = {
    type: typeof SET_INFORMATION_FEED_SEARCH_VALUE,
    payload: string
}

export type INFORMATION_FEED_ACTIONS =
    | SET_INFO_FEED_LIST_ACTION 
    | SET_INFO_FEED_LIST_LOADING_ACTION
    | SET_TOTAL_INFO_FEED_COUNT_ACTION
    | SET_SHOW_TEST_EVENTS_ACTION
    | SET_INFORMATION_FEED_SEARCH_VALUE_ACTION