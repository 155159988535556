export type TAuthReducer = {
    isAuth: boolean,
    loadings: {
        loginLoading: boolean
    }
}


export const SET_AUTH = 'SET_AUTH'
export const SET_LOGIN_LOADING = 'SET_LOGIN_LOADING'

export type SET_AUTH_ACTION = {
    type: typeof SET_AUTH,
    payload: boolean
}

export type SET_LOGIN_LOADING_ACTION = {
    type: typeof SET_LOGIN_LOADING,
    payload: boolean
}

export type AUTH_ACTIONS =
    | SET_AUTH_ACTION
    | SET_LOGIN_LOADING_ACTION