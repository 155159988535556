import {IGateway, IRemoteController, TRemoteControllersHeader, TRemoteControllerSize} from "./types.data";

export type TRemoteControllersReducer = {
    selectedRemoteController: IRemoteController | null
    remoteControllers: IRemoteController[][]
    remoteControllersLoading: boolean

    controllerGatewaysList: IGateway[]
    controllerGatewaysListLoading: boolean

    remoteControllersSize: TRemoteControllerSize
    remoteControllersHeader: TRemoteControllersHeader

    searchValue: string
}

export const SET_REMOTE_CONTROLLERS_LIST = 'SET_REMOTE_CONTROLLERS_LIST'
export const SET_REMOTE_CONTROLLERS_LIST_LOADING = 'SET_REMOTE_CONTROLLERS_LIST_LOADING'

export const SET_SELECTED_REMOTE_CONTROLLER = 'SET_SELECTED_REMOTE_CONTROLLER'

export const SET_REMOTE_CONTROLLERS_SIZE = 'SET_REMOTE_CONTROLLERS_SIZE'
export const SET_REMOTE_CONTROLLERS_HEADER = 'SET_REMOTE_CONTROLLERS_HEADER'

export const SET_REMOTE_CONTROLLERS_SEARCH_VALUE = 'SET_REMOTE_CONTROLLERS_SEARCH_VALUE'

export const SET_GATEWAY_LIST = 'SET_GATEWAY_LIST'
export const SET_GATEWAY_LIST_LOADING = 'SET_GATEWAY_LIST_LOADING'

//=========//

export type SET_REMOTE_CONTROLLERS_LIST_ACTION = {
    type: typeof SET_REMOTE_CONTROLLERS_LIST,
    payload: IRemoteController[][]
}
export type SET_REMOTE_CONTROLLERS_LIST_LOADING_ACTION = {
    type: typeof SET_REMOTE_CONTROLLERS_LIST_LOADING,
    payload: boolean
}

export type SET_SELECTED_REMOTE_CONTROLLER_ACTION = {
    type: typeof SET_SELECTED_REMOTE_CONTROLLER,
    payload: IRemoteController | null
}

export type SET_REMOTE_CONTROLLERS_SIZE_ACTION = {
    type: typeof SET_REMOTE_CONTROLLERS_SIZE,
    payload: TRemoteControllerSize
}
export type SET_REMOTE_CONTROLLERS_HEADER_ACTION = {
    type: typeof SET_REMOTE_CONTROLLERS_HEADER,
    payload: TRemoteControllersHeader
}

export type SET_REMOTE_CONTROLLERS_SEARCH_VALUE_ACTION = {
    type: typeof SET_REMOTE_CONTROLLERS_SEARCH_VALUE,
    payload: string
}

export type SET_GATEWAY_LIST_ACTION = {
    type: typeof SET_GATEWAY_LIST,
    payload: IGateway[]
}
export type SET_GATEWAY_LIST_LOADING_ACTION = {
    type: typeof SET_GATEWAY_LIST_LOADING,
    payload: boolean
}

export type REMOTE_CONTROLLERS_ACTION =
    | SET_REMOTE_CONTROLLERS_LIST_ACTION
    | SET_REMOTE_CONTROLLERS_LIST_LOADING_ACTION
    | SET_SELECTED_REMOTE_CONTROLLER_ACTION
    | SET_REMOTE_CONTROLLERS_SIZE_ACTION
    | SET_REMOTE_CONTROLLERS_HEADER_ACTION
    | SET_REMOTE_CONTROLLERS_SEARCH_VALUE_ACTION
    | SET_GATEWAY_LIST_ACTION
    | SET_GATEWAY_LIST_LOADING_ACTION
