import React, { CSSProperties, useRef } from 'react';
import { IEvent, IReport } from "../../../redux/reducers/reports/types.data";
import { Report } from "./Report";
import { reportsListHeaders } from "../../../helpers/headersHelpers";
import { reportsListFlexes } from "../../../helpers/flexesHelpers";
import clsx from "clsx";
import { Loader } from "../Loader/Loader";


const s = require('./style.module.scss')

interface IMainContentProps {
    reportsList: IEvent[]
    reportsListLoading: boolean
    totalCount: number
    feedScrollTop?: number
    style?: CSSProperties
}

export const Reports: React.FC<IMainContentProps> = ({
    reportsList,
    reportsListLoading,
    totalCount,
    feedScrollTop,
}) => {

    const isMobile: boolean = window.innerWidth < 750

    return (
        <div className={s.reports}>

            {!isMobile && <div style={{ zIndex: 0 }} className={clsx(s.reports_header, feedScrollTop && feedScrollTop >= 60 && s.reports_header__fixed)}>
                {reportsListHeaders.map((value, index) => <div
                    style={{ flex: `0 0 ${reportsListFlexes[index]}%` }}>{value}</div>)}
            </div>}
            <div className={clsx(s.reports_body, reportsListLoading && s.reports_body_loading)}>
                {reportsListLoading
                    ? <Loader />
                    : reportsList && reportsList.length > 0 && reportsList.map((value, index) =>
                        <Report report={value} index={index} />)}
            </div>

        </div>
    );
}


