import {config} from "../config";

const {admin} = config

export const adminPaths = {
    path: admin,
    login: `${admin}/login`,
    users: (page?: number | string) => ({
        path: `${admin}/users/${page}`
    }),
    user: (id?: number | string) => ({
        path: `${admin}/user/${id}`
    }),
    codes: `${admin}/codes`,
    logs: (page?: number | string) => ({
        path: `${admin}/logs/${page}`
    }),
}