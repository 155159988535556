import React from "react";
import clsx from "clsx";

const s = require('./style.module.scss')

interface IFadeAnimProps {
    active: boolean
    onClick?: () => void
}

export const FadeItem: React.FC<IFadeAnimProps> = ({
    active,
    children,
    onClick
}) => {
    return (
        <div onClick={onClick} className={clsx(s.fade, active && s.fade_active)}>
            {children}
        </div>
    )
}