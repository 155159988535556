import React from "react";
import {Button} from "@material-ui/core";
import {OneLoader} from "../Loader/OneLoader";
import clsx from "clsx";

const s = require('./style.module.scss')

interface ISimpleButtonProps {
    onClick: React.MouseEventHandler<HTMLButtonElement>
    color?: "inherit" | "primary" | "secondary" | "default"
    size?: "small" | "medium" | "large"
    loading?: boolean

    [key: string]: any
}

export const SimpleButton: React.FC<ISimpleButtonProps> = ({
                                                               onClick,
                                                               color = 'primary',
                                                               size = 'small',
                                                               loading,
                                                               children,
                                                               ...props
                                                           }) => {
    const classes = clsx(
        s.simple_button,
        props?.disabled && s.simple_button_disabled,
        loading && s.simple_button_loading
    )
    return (
        <Button onClick={onClick}
                color={color}
                size={size}
                variant={"contained"}
                className={classes}
                disabled={props?.disabled || loading}
                {...props}>
            {loading
                ? <OneLoader/>
                : children}
        </Button>
    )
}