import React from "react";
import { SimpleSwitch } from "../../common/Fields/SimpleSwitch/SimpleSwitch";
import { IRemoteControllersSwitchesProps } from "../types";
import { largeCardsText, nameHeaderText, numberHeaderText, smallCardsText } from "../../../helpers/mockHelpers";
import { isMobile } from "../../../helpers/mobileHelpers";

const s = require('./style.module.scss')

export const RemoteControllersSwitches: React.FC<IRemoteControllersSwitchesProps> = ({
    remoteControllersHeader,
    remoteControllersSize,
    setRemoteControllersHeader,
    setRemoteControllersSize,
}) => {


    return (
        <div className={s.switch_wrapper}>
            <div style={{ flex: `0 0 20%` }}>
                <SimpleSwitch checked={remoteControllersSize === 'lg'}
                    onChange={() => remoteControllersSize === 'lg'
                        ? setRemoteControllersSize('xs')
                        : setRemoteControllersSize('lg')}
                    label={remoteControllersSize === 'lg' ? largeCardsText : smallCardsText}
                />
            </div>

            <div className={s.switch_last_item} style={{ flex: `0 0 40%` }}>
                <SimpleSwitch checked={remoteControllersHeader === 'number'}
                    onChange={() => remoteControllersHeader === 'number'
                        ? setRemoteControllersHeader('name')
                        : setRemoteControllersHeader('number')}
                    label={remoteControllersHeader === 'number' ? numberHeaderText : nameHeaderText}
                />
            </div>
        </div>
    )
}