import { SET_CODE_LIST_LOADING, SET_TOTAL_CODE_COUNT } from './../types';
import { SET_CODE_LIST } from '../types';
import { ISetCodesList, ISetCodesListLoading, ISetTotalCodesCount } from './types';


export const setCodesList: ISetCodesList = payload => ({
    type: SET_CODE_LIST,
    payload
})

export const setCodesListLoading: ISetCodesListLoading = payload => ({
    type: SET_CODE_LIST_LOADING,
    payload
})

export const setTotalCodesCount: ISetTotalCodesCount = payload => ({
    type: SET_TOTAL_CODE_COUNT,
    payload
})