// Текстовые значения для поля поиска
export const searchLabel = 'Поиск'
export const searchLabelNumCodes = 'Код'
export const controllersSearchPlaceholder = 'Введите псевдоним, название, адрес, пультовый номер, статус'
export const infoFeedSearchPlaceholder = 'Введите пультовый номер, название объекта, текст события'
export const searchPlaceholderAdmin = 'Введите ФИО, телефон пользователя'
export const searchPlaceholderAdminObject = 'Введите название, псевдоним, панель, пультовый номер, группу'
export const searchPlaceholderNumCodes = 'Введите код'
// Текстовые поля для switch
export const largeCardsText = 'Большие карточки'
export const smallCardsText = 'Маленькие карточки'

export const numberHeaderText = 'Пультовый номер объектов'
export const nameHeaderText = 'Название объектов'

export const objectsMock = [
    {id: 1, name: 'Test'},
    {id: 2, name: 'Test1'},
    {id: 3, name: 'Test2'},
    {id: 4, name: 'Test3'}
]