import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import {AdminUsersContainer} from "../../components/AdminUsers/AdminUsers";
import {ROUTE_PATHS} from "../paths.main";
import {ManagementAdminUsersContainer} from "../../components/AdminUsers/UserManagement/ManagementAdminUsers/ManagementAdminUsers";
import {AdminSettingsContainer} from "../../components/AdminUsers/AdminSettings/AdminSettings";
import {LogsContainer} from "../../components/AdminUsers/Logs/Logs";

export const AdminSwitch: React.FC = () => {
    return (

        <Switch>
            <Route exact
                   path={'/admin/users/:page'}
                   component={AdminUsersContainer}/>
            <Route exact
                   path={'/admin/user/:id'}
                   component={ManagementAdminUsersContainer}/>
            <Route exact
                   path={ROUTE_PATHS.admin.codes}
                   component={AdminSettingsContainer}/>
            <Route exact
                   path={'/admin/logs/:page'}
                   component={LogsContainer}/>
        </Switch>

    )
}