import {Pagination} from 'antd';
import React from 'react'
import {itemRender} from './itemRender';
import {IPagination} from "./types";
import clsx from "clsx";

const classes = require('./classes.module.scss')


export const SimplePagination: React.FC<IPagination> = ({
                                                            total,
                                                            onChange,
                                                            ...props
                                                        }) => {
    return (
        <div className={clsx(classes.pagination, props.className)}>
            <Pagination
                total={total}
                onChange={onChange}
                itemRender={itemRender}
                showSizeChanger={false}
                pageSize={props?.pageSize}
                current={props?.currentPage}
                {...props}
            />
        </div>
    )
}