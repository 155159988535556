import { TResponsibilityReducer, 
    RESPONSIBILITY_LIST_ACTIONS,
    SET_RESPONSIBILITY_LIST, 
    SET_RESPONSIBILITY_LIST_LOADING,
    SET_TOTAL_RESPONSIBILITY_LIST_COUNT
} from './types';


const init: TResponsibilityReducer = {
    responsibilityList: [],
    responsibilityListLoading: false,
    totalCount: 4,
}

export const responsibilityListReducer = (state = init, action: RESPONSIBILITY_LIST_ACTIONS) => {
    switch(action.type) {
        case SET_RESPONSIBILITY_LIST: {
            return {
                ...state,
                responsibilityList: action.payload
            }
        }
        case SET_RESPONSIBILITY_LIST_LOADING: {
            return {
                ...state,
                responsibilityListLoading: action.payload
            }
        }
        case SET_TOTAL_RESPONSIBILITY_LIST_COUNT: {
            return {
                ...state,
                totalCount: action.payload
            }
        }
        default: 
            return state
    }
}