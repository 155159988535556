import React, { useEffect } from "react";
import { IRemoteControllersProps } from "../types";
import { TRootState } from "../../../redux/store";
import { connect } from "react-redux";
import {
    setRemoteControllersHeader,
    setRemoteControllersSize, setSelectedRemoteController
} from "../../../redux/reducers/userPanel/remoteControllers/actions/actions";
import { SearchField } from "../../common/SearchField/SearchField";
import { RemoteControllersSwitches } from "./RemoteControllersSwitches";
import { RemoteControllerLarge } from "./RemoteControllerCard/RemoteControllerLarge";
import { getRemoteControllersAsync } from "../../../redux/reducers/userPanel/remoteControllers/actions";
import { Loader } from "../../common/Loader/Loader";
import clsx from "clsx";
import { RemoteControllerSmall } from "./RemoteControllerCard/RemoteControllerSmall";
import { controllersSearchPlaceholder, searchLabel } from "../../../helpers/mockHelpers";
import { IRemoteController } from "../../../redux/reducers/userPanel/remoteControllers/types.data";
import { isMobile } from "../../../helpers/mobileHelpers";

const s = require('./style.module.scss')

const RemoteControllers: React.FC<IRemoteControllersProps> = ({
    getRemoteControllersAsync,
    remoteControllers,
    remoteControllersLoading,

    remoteControllersSize,
    setRemoteControllersSize,
    remoteControllersHeader,
    setRemoteControllersHeader,

    setSelectedRemoteController,

    dragRef,
    height,
}) => {

    return (
        <div className={s.remote_controllers} style={{ height }} id={'objectCards'}>
            <SearchField label={searchLabel}
                placeholder={controllersSearchPlaceholder}
                withClear
                onSearch={(value: string) => {
                    getRemoteControllersAsync({
                        searchValue: value || undefined
                    })
                }}
            />

            <RemoteControllersSwitches remoteControllersHeader={remoteControllersHeader}
                remoteControllersSize={remoteControllersSize}
                setRemoteControllersHeader={setRemoteControllersHeader}
                setRemoteControllersSize={setRemoteControllersSize}
            />

            <div className={
                clsx(remoteControllersLoading && remoteControllers.length === 0 && s.main_content_loading, remoteControllersSize === 'xs' ? s.main_content_small : s.main_content)}>
                {remoteControllersLoading && remoteControllers.length === 0
                    ? <Loader />
                    : remoteControllers.length > 0 ? (
                        remoteControllersSize === "lg"
                            ? remoteControllers
                                .map((controllersGroup, controllersGroupIndex) => {
                                    return controllersGroup.map((controller, controllerIndex) => {
                                        return <RemoteControllerLarge
                                            key={`${controller.idDevice}-contr-large-${controllerIndex}`}
                                            controller={controller}
                                            controllersGroupIndex={controllersGroupIndex}
                                            isFirstInGroup={controllerIndex === 0 && controllersGroup?.length > 1}
                                            isLastInGroup={controllerIndex === controllersGroup.length - 1 && controllersGroup?.length > 1}
                                            remoteControllersHeader={remoteControllersHeader}
                                            setSelectedRemoteController={setSelectedRemoteController}
                                        />
                                    })
                                })
                            : remoteControllers
                                .map((controllersGroup, controllersGroupIndex) => {
                                    return <RemoteControllerSmall key={`contr-small-${controllersGroupIndex}`}
                                        controllersGroup={controllersGroup}
                                        remoteControllersHeader={remoteControllersHeader}
                                        setSelectedRemoteController={setSelectedRemoteController}
                                    />
                                })) : 'Нет данных!'}
            </div>

            {!isMobile && <div className={s.divider} ref={dragRef} />}
        </div>
    )
}

const mapStateToProps = (state: TRootState) => ({
    remoteControllersSize: state.remoteController.remoteControllersSize,
    remoteControllersHeader: state.remoteController.remoteControllersHeader,
    remoteControllers: state.remoteController.remoteControllers,
    remoteControllersLoading: state.remoteController.remoteControllersLoading,
})
const mapDispatchToProps = {
    getRemoteControllersAsync,
    setRemoteControllersSize,
    setRemoteControllersHeader,
    setSelectedRemoteController,
}

export const RemoteControllersContainer = connect(mapStateToProps, mapDispatchToProps)(RemoteControllers)
