export type TLaunchReducer = {
    appLoading: boolean
    isAdmin: boolean
}

export const INIT_APP = 'INIT_APP'
export const SET_INIT_APP_LOADING = 'SET_INIT_APP_LOADING'
export const SET_IS_ADMIN = 'SET_IS_ADMIN'

export type INIT_APP_ACTION = {
    type: typeof INIT_APP,
}
export type SET_INIT_APP_LOADING_ACTION = {
    type: typeof SET_INIT_APP_LOADING,
    payload: boolean
}
export type SET_IS_ADMIN_ACTION = {
    type: typeof SET_IS_ADMIN,
    payload: boolean
}

export type LAUNCH_ACTIONS =
    | INIT_APP_ACTION
    | SET_INIT_APP_LOADING_ACTION
    | SET_IS_ADMIN_ACTION