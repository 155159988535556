import {notification} from "antd";

export type TApiError = {
    errorCode: string
    errorMessage: string
    index?: number
}

export type TAntdNotificationType = 'success' | 'info' | 'warning' | 'error'
export type TAntdNotificationPayload =  {
    type: TAntdNotificationType
    message: string
    description?: string
    duration?: number
}

export const isArrays = (...rest: any[]) =>
    !!(rest && Array.isArray(rest) && rest.every((r) => Array.isArray(r)))

export function checkError(err: any) {
    let response: TApiError[] = err.response?.data?.errorMessages

    if(!response) {
        openNotification({ type:'error', message: err.message || 'Неизвестная ошибка' })
    }

    response = isArrays(response)
        ? response.map((r, index) => ({ ...r, index }))
        : []
    response.map(r => openNotification({type: "error", message: r.errorMessage}))
}

export function openNotification(payload: TAntdNotificationPayload) {
    const {type, ...props} = payload
    notification[type]({
        ...props
    });
}