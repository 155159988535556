import React, { memo, useEffect, useState } from "react";
import { IReportsPageProps } from "./types";
import { TRootState } from "../../redux/store";
import { connect } from "react-redux";
import { getFilterObjectListAsync, getReportsListAsync } from "../../redux/reducers/reports/actions";
import { Reports } from "../common/Reports/Reports";
import { PageTitle } from "../common/PageTitle/PageTitle";
import { MultipleSelect } from "../common/Fields/MultipleSelect/MultipleSelect";
import clsx from "clsx";
import { RangeDatePicker } from "../common/Fields/RangeDatePicker/RangeDatePicker";
import { SimpleButton } from "../common/Buttons/SimpleButton";
import { SimplePagination } from "../common/SimplePagination/Pagination";
import { useHistory, useRouteMatch } from "react-router-dom";
import { ROUTE_PATHS } from "../../routers/paths.main";
import { instance } from "../../redux/api";
import { checkError, openNotification } from "../../utils/helpers";
import { IEvent, IReport } from "../../redux/reducers/reports/types.data";
import * as XLSX from "xlsx";
import FileSaver from "file-saver";
import { Loader } from "../common/Loader/Loader";
import { formatISOToDateString, formatISOToTimeString } from "../../helpers/dateHelpers";
import { setReportsList, setTotalReportsCount } from "../../redux/reducers/reports/actions/actions";
import moment, { Moment } from "moment";
import { isMobile } from "../../helpers/mobileHelpers";
import { ConfigProvider, DatePicker } from "antd";
const s = require('./style.module.scss')
const ReportsPage: React.FC<IReportsPageProps> = memo(({
    reportsList,
    reportsListLoading,
    getReportsListAsync,
    getFilterObjectListAsync,
    totalCount,
    isPage,
    filterObjectList,
    setReportsList,
    setTotalReportsCount,
}) => {
    const PAGE_SIZE = 20
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const history = useHistory()
    const match: any = useRouteMatch()

    const [startDate, setStartDate] = useState<Moment | null>(moment(new Date()))
    const [endDate, setEndDate] = useState<Moment | null>(moment(startDate).add(1, 'd'))
    const [selectedObjects, setSelectedObjects] = useState<number[]>([])

    const [currentPage, setCurrentPage] = useState<number>(1)
    const [showBtnClicked, setShowBtnClicked] = useState<boolean>(false)

    const [allReportsLoading, setAllReportsLoading] = useState<boolean>(false)

    const exportCSVHandler = () => {
        if (!startDate || !endDate) {
            openNotification({ type: "warning", message: "Выберите период!" })
            return
        }
        setAllReportsLoading(true)
        instance().close().getReportsList({
            flag: "A",
            dateBeg: startDate?.toDate()?.toISOString(),
            dateEnd: endDate?.toDate()?.toISOString(),
            objIds: selectedObjects,
        })
            .then(res => {
                const ws = XLSX.utils.json_to_sheet(res.data?.events?.map((item: IEvent) => ({
                    "Дата": `${formatISOToDateString(item.date)} ${formatISOToTimeString(item.date)}`,
                    "Тип": item.typeEvent,
                    "Сообщение": item.textEvent,
                    // "Группа": item.
                    // "Код": item.
                    "Название": item.siteName,
                })) || []);
                const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
                ws['!cols'] = [{ wch: 35 },
                { wch: 60 },
                { wch: 60 },
                { wch: 40 }];
                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                const data = new Blob([excelBuffer], { type: fileType });
                FileSaver.saveAs(data, `${formatISOToDateString(startDate?.toDate()?.toISOString())}-${formatISOToDateString(endDate?.toDate()?.toISOString())}` + fileExtension);
            })
            .catch(err => {
                checkError(err)
            })
            .finally(() => setAllReportsLoading(false))
    }

    const clearHandler = () => {
        setStartDate(null)
        setEndDate(null)
        setSelectedObjects([])
        getReportsListAsync({
            offset: isPage
                ? (+match?.params?.page - 1) * PAGE_SIZE
                : (currentPage - 1) * PAGE_SIZE,
            count: PAGE_SIZE,
        })
    }

    useEffect(() => {
        getFilterObjectListAsync()

        return () => {
            setReportsList([])
            setTotalReportsCount(0)
        }
    }, [])

    useEffect(() => {
        setCurrentPage(+match?.params?.page)
    }, [match])

    useEffect(() => {
        showBtnClicked && getReportsListAsync({
            offset: isPage
                ? (+match?.params?.page - 1) * PAGE_SIZE
                : (currentPage - 1) * PAGE_SIZE,
            count: PAGE_SIZE,
            dateBeg: startDate ? startDate?.toDate()?.toISOString() : undefined,
            dateEnd: endDate ? endDate?.toDate()?.toISOString() : undefined,
        })
    }, [currentPage])

    return (
        <div className={clsx(isPage ? s.reports_page : s.reports_component)}>
            {isPage && <PageTitle title={'Отчёты'} />}
            <div className={s.reports_page_filter}>
                <RangeDatePicker
                    maxWidth={365}
                    value={[startDate, endDate]}
                    onChange={(values, formatString) => {
                        if (values && values?.length > 0) setStartDate(values[0] || setStartDate(null))
                        else setStartDate(null)
                        if (values && values?.length > 1) setEndDate(values[1] || setEndDate(null))
                        else setEndDate(null)
                    }}
                />
                <MultipleSelect selectedItems={selectedObjects}
                    onChange={setSelectedObjects}
                    items={filterObjectList.map(item => ({ id: item.id, name: item.obj }))} />
                <SimpleButton onClick={() => {
                    if (!reportsListLoading) {

                        if (!startDate || !endDate) {
                            openNotification({ type: "warning", message: "Выберите период!" })
                            return
                        }

                        getReportsListAsync({
                            offset: isPage
                                ? (+match?.params?.page - 1) * PAGE_SIZE
                                : (currentPage - 1) * PAGE_SIZE,
                            count: PAGE_SIZE,
                            dateBeg: startDate?.toDate()?.toISOString() || undefined,
                            dateEnd: endDate?.toDate()?.toISOString() || undefined,
                            objIds: selectedObjects.length > 0 ? selectedObjects : undefined
                        })

                        setShowBtnClicked(true)
                    }
                }}>
                    Показать
                </SimpleButton>

                {!isMobile && <div className={allReportsLoading ? s.loading : ''}>
                    <SimpleButton onClick={exportCSVHandler}
                        color={"default"}>Скачать</SimpleButton>
                </div>}

                <SimpleButton onClick={clearHandler}
                    color={"secondary"}>Очистить поиск</SimpleButton>
            </div>

            {reportsList?.length > 0 && <Reports reportsList={reportsList}
                reportsListLoading={reportsListLoading}
                totalCount={totalCount}
            />}

            {
                totalCount
                    ? <SimplePagination total={totalCount}
                        onChange={page => {
                            isPage
                                ? history.push(ROUTE_PATHS.report(page).path)
                                : setCurrentPage(page)
                        }}
                        pageSize={PAGE_SIZE}
                        currentPage={currentPage}
                        className={s.pagination}
                    />
                    : <div className={s.nodata}>Нет данных!</div>
            }

            {allReportsLoading && <Loader fullScreen />}
        </div>
    )
}
)

const mapStateToProps = (state: TRootState) => ({
    reportsList: state.reports.reportsList,
    reportsListLoading: state.reports.reportsListLoading,
    totalCount: state.reports.totalCount,
    filterObjectList: state.reports.filterObjectList
})

const mapDispatchToProps =
{
    getReportsListAsync,
    getFilterObjectListAsync,
    setReportsList,
    setTotalReportsCount,
}

export const ReportsPageContainer = connect(mapStateToProps, mapDispatchToProps)(ReportsPage)
