import {
    SET_IUD_USER_LOADING,
    SET_IUD_USER_OBJECT_LOADING,
    SET_SELECTED_USER,
    SET_SYSTEMS_LIST,
    SET_SYSTEMS_LIST_LOADING,
    SET_TOTAL_USER_LIST_COUNT,
    SET_TOTAL_USER_OBJECT_LIST_COUNT,
    SET_USER_LIST,
    SET_USER_LIST_LOADING,
    SET_USER_OBJECT_LIST,
    SET_USER_OBJECT_LIST_LOADING,
    TUserListReducer,
    USER_LIST_ACTIONS
} from "./types";


const init: TUserListReducer = {
    userList: [],
    userListLoading: false,
    selectedUser: null,
    userObjectsList: [],
    userObjectsListLoading: false,
    totalCount: 0,
    totalUserObjectListCount: 0,
    iudUserLoading: false,
    systemsList: [],
    systemsListLoading: false,
    iudUserObjectLoading: false,
}

export const userListReducer = (state = init, action: USER_LIST_ACTIONS) => {
    switch (action.type) {
        case SET_USER_LIST:{
            return {
                ...state,
                userList: action.payload
            }
            
        }
        case SET_USER_LIST_LOADING:{
            return {
                ...state,
                userListLoading: action.payload
            }
        }
        case SET_SELECTED_USER: {
            return {
                ...state,
                selectedUser: action.payload
            }
        }
        case SET_TOTAL_USER_LIST_COUNT:{
            return {
                ...state,
                totalCount: action.payload
            }
        }

        case SET_USER_OBJECT_LIST: {
            return {
                ...state,
                userObjectsList: action.payload
            }
        }
        case SET_USER_OBJECT_LIST_LOADING: {
            return {
                ...state,
                userObjectsListLoading: action.payload
            }
        }
        case SET_TOTAL_USER_OBJECT_LIST_COUNT: {
            return {
                ...state,
                totalUserObjectListCount: action.payload
            }
        }
        case SET_IUD_USER_LOADING: {
            return {
                ...state,
                iudUserLoading: action.payload
            }
        }
        case SET_SYSTEMS_LIST: {
            return {
                ...state,
                systemsList: action.payload
            }
        }
        case SET_SYSTEMS_LIST_LOADING: {
            return {
                ...state,
                systemsListLoading: action.payload
            }
        }
        case SET_IUD_USER_OBJECT_LOADING: {
            return {
                ...state,
                iudUserObjectLoading: action.payload
            }
        }
        default: 
            return state
    }
}