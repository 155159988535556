import {Select} from "antd";
import React, {memo} from "react";
import {TEnumItem} from "../../../../redux/globalTypes";
const {Option} = Select;
const classes = require('./style.module.scss')
interface ISimpleSelectProps {
    readonly items: TEnumItem[]
    placeholder?: string
    onChange: (value: any) => void
    value?: string | number // obj id / simple id
    size?: 'xs' | 'md' | 'lg'
    showSearch?: boolean
    [key: string]: any
}

export const SimpleSelect: React.FC<ISimpleSelectProps> = memo(({
                                                                    placeholder = 'Выберите',
                                                                    items,
                                                                    onChange,
                                                                    showSearch = false,
                                                                    size = 'lg',
                                                                    ...props
                                                                }) => {
    return (
            <Select
                getPopupContainer={trigger => trigger.parentNode}
                className={classes.simple_select}
                dropdownStyle={{zIndex: 1000000}}
                dropdownClassName={classes.dropdown}
                showSearch={showSearch}
                style={{width: '100%',  borderRadius: 4}}
                placeholder={placeholder}
                optionFilterProp="children"
                onChange={onChange}
                filterOption={(input, option) =>
                    option?.children && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                {...props}
                value={props.value ? props.value : undefined}
            >
                {!props?.loading && items?.map((item => (
                    <Option key={item.id} value={item.id}>{item.name}</Option>
                )))}
            </Select>
    )
})