import { IResponsibility } from "./types.data";

export type TResponsibilityReducer = {
    responsibilityList: IResponsibility[]
    responsibilityListLoading: boolean
    totalCount: number

}

export const SET_RESPONSIBILITY_LIST = 'SET_RESPONSIBILITY_LIST'
export const SET_RESPONSIBILITY_LIST_LOADING = 'SET_RESPONSIBILITY_LIST_LOADING'
export const SET_TOTAL_RESPONSIBILITY_LIST_COUNT = 'SET_TOTAL_RESPONSIBILITY_LIST_COUNT'

export type SET_RESPONSIBILITY_LIST_ACTION = {
    type: typeof SET_RESPONSIBILITY_LIST,
    payload: IResponsibility[]
}
export type SET_RESPONSIBILITY_LIST_LOADING_ACTION = {
    type: typeof SET_RESPONSIBILITY_LIST_LOADING,
    payload: boolean
}
export type SET_TOTAL_RESPONSIBILITY_LIST_COUNT_ACTION = {
    type: typeof SET_TOTAL_RESPONSIBILITY_LIST_COUNT,
    payload: number
}

export type RESPONSIBILITY_LIST_ACTIONS = 
    | SET_RESPONSIBILITY_LIST_ACTION
    | SET_RESPONSIBILITY_LIST_LOADING_ACTION
    | SET_TOTAL_RESPONSIBILITY_LIST_COUNT_ACTION