import React, { useState } from 'react';
import { IModal } from '../../common/Modal/types';
import { Modal } from "../../common/Modal/Modal";
import { SimpleInput } from "../../common/Fields/SimpleInput/SimpleInput";
import { SimpleButton } from "../../common/Buttons/SimpleButton";
import { TRootState } from "../../../redux/store";
import { connect } from "react-redux";
import { iudDeviceContractorAsync } from "../../../redux/reducers/adminUsers/actions";
import { TEnumItem } from "../../../redux/globalTypes";
import { IIudDeviceContractor } from "../../../redux/reducers/adminUsers/actions/types";
import { SimpleSelect } from "../../common/Fields/MultipleSelect/SimpleSelect";
import { openNotification } from "../../../utils/helpers";
import { TSelDevicePart } from "../../../redux/api/requests/admin/type.data";

const s = require('./style.module.scss');

interface IAddObjectModalProps extends IModal {
    systemsList: TEnumItem[],
    iudDeviceContractorAsync: IIudDeviceContractor
    searchPayload: TSelDevicePart
    userId: number
}

const AddObjectModal: React.FC<IAddObjectModalProps> = ({
    open,
    closeHandler,
    systemsList,
    iudDeviceContractorAsync,
    searchPayload,
    userId,
}) => {
    const [nameDevice, setNameDevice] = useState<string>('')
    const [idSystem, setIdSystem] = useState<string | number>(1)
    const [numberDevice, setNumberDevice] = useState<string>('')
    const [namePart, setNamePart] = useState<string>('')

    const saveDeviceContractorHandler = () => {
        if (!nameDevice || !idSystem || !numberDevice || !namePart) {
            openNotification({ type: "warning", message: "Заполните все данные!" })
            return
        }

        iudDeviceContractorAsync({
            flag: "I",
            nameDevice,
            idSystem,
            numberDevice,
            namePart,
            idUser: userId
        },
            () => {
                setNameDevice('')
                setNumberDevice('')
                setNamePart('')
                closeHandler()
            }, searchPayload)
    }

    return (
        <Modal open={open} closeHandler={closeHandler} title={'Добавить объект'}>
            <div className={s.simple_input_test}>
                <SimpleInput className={s.input_modal_item}
                    value={nameDevice}
                    onChange={e => setNameDevice(e.target.value)}
                    label={'Псевдоним'}
                    placeholder={'Укажите псевдоним'}
                />
                <SimpleSelect className={s.input_modal_item}
                    value={idSystem}
                    onChange={setIdSystem}
                    label={'Панель'}
                    placeholder={'Выберите панель'}
                    items={systemsList}
                />
                <SimpleInput className={s.input_modal_item}
                    value={numberDevice}
                    onChange={e => setNumberDevice(e.target.value)}
                    label={'Пультовый номер'}
                    placeholder={'Укажите пультовый номер'}
                />
                <SimpleInput className={s.input_modal_item}
                    value={namePart}
                    onChange={e => setNamePart(e.target.value)}
                    label={'Группа'}
                    placeholder={'Укажите группу'}
                />
            </div>
            <SimpleButton className={s.btn_bottom} onClick={saveDeviceContractorHandler}>
                Сохранить
            </SimpleButton>
        </Modal>

    )
}
const mapStateToProps = (state: TRootState) => ({
    systemsList: state.adminUsers.systemsList
})
const mapDispatchToProps = {
    iudDeviceContractorAsync
}

export const AddObjectModalContainer = connect(mapStateToProps, mapDispatchToProps)(AddObjectModal)