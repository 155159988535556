import {setInfoFeedList, setInfoFeedListLoading, setTotalInfoFeedCount} from "./action";
import {instance} from "../../../../api";
import {checkError, openNotification} from "../../../../../utils/helpers";
import {store} from "../../../../store";

export const getInfoFeedListAsync = (body, onSuccess) => async dispatch => {
    await dispatch(setInfoFeedListLoading(true))
    await instance().close().getInformationFeedList(body)
        .then(res => {
            const infoFeedList = store.getState().informationFeed.infoFeedList
            dispatch(setInfoFeedList(res.data?.events))
            dispatch((setTotalInfoFeedCount(res.data?.totalcount)))
        })
        .catch(err => {
            checkError(err)
        })
        .then(() => {
            dispatch(setInfoFeedListLoading(false))
        })
}

export const getInfoFeedListFromSocket = (stop) => async dispatch => {

}