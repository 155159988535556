import {SET_USER_INFO, SET_USER_INFO_LOADING, TUserReducer, USER_ACTIONS} from "./types";

const init: TUserReducer= {
    userInfo: null,
    userInfoLoading: false
}

export const userReducer = (state = init, action: USER_ACTIONS) => {
    switch (action.type) {
        case SET_USER_INFO: {
            return {
                ...state,
                userInfo: action.payload
            }
        }
        case SET_USER_INFO_LOADING: {
            return {
                ...state,
                userInfoLoading: action.payload
            }
        }
        default:
            return state
    }
}