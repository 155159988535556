import {IFilterObj, IReport} from "./types.data";


export type TReportsReducer = {
    reportsList: IReport[]
    reportsListLoading: boolean
    totalCount: number

    filterObjectList: IFilterObj[]
    filterObjectListLoading: boolean
}
export const SET_REPORTS_LIST = 'SET_REPORTS_LIST'
export const SET_REPORTS_LIST_LOADING = 'SET_REPORTS_LIST_LOADING'
export const SET_TOTAL_REPORTS_COUNT = 'SET_TOTAL_REPORTS_COUNT'

export const SET_FILTER_OBJECT_LIST = 'SET_FILTER_OBJECT_LIST'
export const SET_FILTER_OBJECT_LIST_LOADING = 'SET_FILTER_OBJECT_LIST_LOADING'

export type SET_REPORTS_LIST_ACTION = {
    type: typeof SET_REPORTS_LIST,
    payload: IReport[]
}
export type SET_REPORTS_LIST_LOADING_ACTION = {
    type: typeof SET_REPORTS_LIST_LOADING,
    payload: boolean
}
export type SET_TOTAL_REPORTS_COUNT_ACTION = {
    type: typeof SET_TOTAL_REPORTS_COUNT,
    payload: number
}
export type SET_FILTER_OBJECT_LIST_ACTION = {
    type: typeof SET_FILTER_OBJECT_LIST,
    payload: IFilterObj[]
}
export type SET_FILTER_OBJECT_LIST_LOADING_ACTION = {
    type: typeof SET_FILTER_OBJECT_LIST_LOADING,
    payload: boolean
}

export type REPORTS_ACTIONS =
    | SET_REPORTS_LIST_ACTION
    | SET_REPORTS_LIST_LOADING_ACTION
    | SET_TOTAL_REPORTS_COUNT_ACTION
    | SET_FILTER_OBJECT_LIST_ACTION
    | SET_FILTER_OBJECT_LIST_LOADING_ACTION