import {paths} from './../../paths';
import {instanceAxiosClose} from '../../instance.axios';
import {
    TGetUserListBody,
    TGetVisibleCodeList,
    TIUDDeviceContractor,
    TiudUser,
    TiudVisibleCode,
    TSelDevicePart, TSelLogs
} from "./type.data";
import {TAuthBody} from "../auth/types";
import {TiudFlagType} from "../../../globalTypes";

const getPathForUserAction = (flag: TiudFlagType) => {
    switch (flag) {
        case "I": // ДЭБИЛЫ
            return paths.registerUser // БЭКЕНДЕРЫ
        case "D": // СДЕЛАЛИ
            return paths.unRegisterUser // РАЗНЫЕ
        case "U": // РУТЫ
            return paths.iudContractor // ДЛЯ ОДНОГО
        default: return "client error" // И ТОГО ЖЕ!!!!!!!!!!!
    }
}
export const adminRequests = () => ({
    open: () => ({}),
    close: (token: string | null) => ({
        iudUser: (body: TiudUser) => {
            return instanceAxiosClose(token).post(getPathForUserAction(body.flag), body)
        },
        getUserList: (body: TGetUserListBody) => {
            return instanceAxiosClose(token).post(paths.selContractor, body)
        },
        getVisibleCode: (body: TGetVisibleCodeList) => {
            return instanceAxiosClose(token).post(paths.selVisibleCode, body)
        },
        iudVisibleCode: (body: TiudVisibleCode) => {
            return instanceAxiosClose(token).post(paths.iudVisibleCode, body)
        },
        addOrChangeUser: (body: TAuthBody) => {
            return instanceAxiosClose(token).post(paths.addOrChangeUser, body)
        },

        selDevicePart: (body: TSelDevicePart) => {
            return instanceAxiosClose(token).post(paths.selDevicePart, body)
        },
        iudDeviceContractor: (body: TIUDDeviceContractor) => {
            return instanceAxiosClose(token).post(paths.iudDeviceContractor, body)
        },
        selSystems: () => {
            return instanceAxiosClose(token).post(paths.selSystems, {})
        },
        selLogs: (body: TSelLogs) => {
            return instanceAxiosClose(token).post(paths.selLogs, body)
        }
    })
})