import React, { FC } from "react";
import { ILog } from "../../../redux/reducers/logs/types.data";
import { Fade } from "@material-ui/core";
import { formatISOToDateString, formatISOToTimeString } from "../../../helpers/dateHelpers";
import { Tooltip } from "antd";

const s = require('./style.module.scss')

interface ILogCardProps {
    log: ILog,
    index: number
}

export const LogCard: FC<ILogCardProps> = ({
    log,
    index,

}) => {
    const { action, dataIn, dataOut, date, id, user } = log
    return (
        <Fade in timeout={{ enter: 100 * index }}>
            <div className={s.logCard}>
                <div className={s.item}>
                    <div className={s.title}>
                        Дата
                    </div>
                    <div>{date && formatISOToDateString(date)} {date && formatISOToTimeString(date).split('.')[0]}</div>
                </div>
                <div className={s.item}>
                    <div className={s.title}>
                        Пользователь
                    </div>
                    <Tooltip title={user} placement={"bottom"}>
                        {user}
                    </Tooltip>
                </div>
                {dataOut && <div className={s.item}>
                    <div className={s.title}>
                        Выходные данные
                    </div>
                    <Tooltip title={dataOut} placement={"bottom"}>
                        {dataOut}
                    </Tooltip>
                </div>}
                <div className={s.item}>
                    <div className={s.title}>
                        Ошибка
                    </div>
                    <Tooltip title={action} placement={"bottom"}>
                        {action}
                    </Tooltip>
                </div>
                {dataIn && <div className={s.item}>
                    <div className={s.title}>
                        Входные данные
                    </div>
                    <Tooltip title={dataIn} placement={"bottom"}>
                        {dataIn}
                    </Tooltip>
                </div>}
            </div>
        </Fade>
    )
}