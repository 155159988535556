import {INavItem} from "./types.data";

export type TSidebarReducer = {
    adminPages: INavItem[]
    clientPages: INavItem[]

    currentPage: INavItem
}

export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE'

export type SET_CURRENT_PAGE_ACTION = {
    type: typeof SET_CURRENT_PAGE,
    payload: INavItem
}

export type SIDEBAR_ACTIONS =
    | SET_CURRENT_PAGE_ACTION