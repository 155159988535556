import React, { FC, useEffect, useState } from 'react'
import { ILog } from "../../../redux/reducers/logs/types.data";
import { TRootState } from "../../../redux/store";
import { connect } from "react-redux";
import { selLogsAsync } from "../../../redux/reducers/logs/actions";
import { ISelLogs } from "../../../redux/reducers/logs/actions/types";
import { PageTitle } from "../../common/PageTitle/PageTitle";
import { RangeDatePicker } from "../../common/Fields/RangeDatePicker/RangeDatePicker";
import { SimpleButton } from "../../common/Buttons/SimpleButton";
import { useHistory, useRouteMatch } from "react-router-dom";
import { SearchField } from "../../common/SearchField/SearchField";
import { searchLabel } from "../../../helpers/mockHelpers";
import { SimplePagination } from "../../common/SimplePagination/Pagination";
import { ROUTE_PATHS } from "../../../routers/paths.main";
import { Loader } from "../../common/Loader/Loader";
import { Log } from "./Log";
import { LogCard } from './LogCard';
import moment, { Moment } from "moment";
const s = require('./style.module.scss')

interface ILogsProps {
    logsList: ILog[]
    logsListLoading: boolean
    totalCount: number
    selLogsAsync: ISelLogs
}

const Logs: FC<ILogsProps> = ({
    logsList,
    logsListLoading,
    selLogsAsync,
    totalCount,
}) => {
    const PAGE_SIZE = 15

    const history = useHistory()
    const match: any = useRouteMatch()
    const isMobile: boolean = window.innerWidth < 750


    const [searchValue, setSearchValue] = useState<string>('')
    const [startDate, setStartDate] = useState<Moment | null>(moment(new Date()))
    const [endDate, setEndDate] = useState<Moment | null>(moment(startDate).add(1, 'd'))

    const [currentPage, setCurrentPage] = useState<number>(1)


    const clearHandler = () => {
        setStartDate(null)
        setEndDate(null)
        setSearchValue('')
        selLogsAsync({
            offset: (+match?.params?.page - 1) * PAGE_SIZE,
            count: PAGE_SIZE,
        })
    }


    useEffect(() => {
        setCurrentPage(+match?.params?.page)
    }, [match])

    useEffect(() => {
        selLogsAsync({
            offset: (+match?.params?.page - 1) * PAGE_SIZE,
            count: PAGE_SIZE,
            searchValue: searchValue || undefined,
            dateBeg: startDate?.toDate()?.toISOString() || undefined,
            dateEnd: endDate?.toDate()?.toISOString() || undefined,
        })
    }, [currentPage])

    return (
        <div className={s.page}>
            <PageTitle title={'Логирование и ошибки'} />
            <div className={s.filter}>
                <RangeDatePicker
                    value={[startDate, endDate]}
                    onChange={(values, formatString) => {
                        if (values && values?.length > 0) setStartDate(values[0] || setStartDate(null))
                        else setStartDate(null)
                        if (values && values?.length > 1) setEndDate(values[1] || setEndDate(null))
                        else setEndDate(null)
                    }} />

                <SearchField onSearch={(value: string) => setSearchValue(value)}
                    value={searchValue}
                    label={searchLabel}
                    placeholder={''}
                />

                <SimpleButton onClick={() => {
                    !logsListLoading &&
                        selLogsAsync({
                            offset: (+match?.params?.page - 1) * PAGE_SIZE,
                            count: PAGE_SIZE,
                            searchValue,
                            dateEnd: startDate?.toDate()?.toISOString() || undefined,
                            dateBeg: endDate?.toDate()?.toISOString() || undefined,
                        })
                }}>
                    Показать
                </SimpleButton>
                <SimpleButton onClick={clearHandler} color={"secondary"}>
                    Очистить поиск
                </SimpleButton>
            </div>
            <div className={s.logs}>
                {logsListLoading
                    ? <Loader />
                    : logsList && logsList.length > 0 && logsList
                        .map((log, logIndex) => {
                            if (!isMobile) return <Log key={log.id}
                                log={log}
                                index={logIndex}
                            />
                            else return <LogCard key={log.id}
                                log={log}
                                index={logIndex}
                            />
                        })}
            </div>

            <SimplePagination total={totalCount}
                onChange={page => history.push(ROUTE_PATHS.admin.logs(page).path)}
                pageSize={PAGE_SIZE}
                currentPage={currentPage}
                className={s.pagination}
            />
        </div>
    )
}

const mapStateToProps = (state: TRootState) => ({
    logsList: state.logs.logsList,
    logsListLoading: state.logs.logsListLoading,
    totalCount: state.logs.totalCount
})
const mapDispatchToProps = {
    selLogsAsync
}

export const LogsContainer = connect(mapStateToProps, mapDispatchToProps)(Logs)