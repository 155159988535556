import {LOGS_ACTIONS, SET_LOGS_LIST, SET_LOGS_LIST_LOADING, SET_LOGS_TOTAL_COUNT, TLogsReducer} from "./types";

const init: TLogsReducer = {
    logsList: [],
    logsListLoading: false,
    totalCount: 0,
}

export const logsReducer = (state = init, action: LOGS_ACTIONS) => {
    switch (action.type) {
        case SET_LOGS_LIST: {
            return {
                ...state,
                logsList: action.payload
            }
        }
        case SET_LOGS_LIST_LOADING: {
            return {
                ...state,
                logsListLoading: action.payload
            }
        }
        case SET_LOGS_TOTAL_COUNT: {
            return {
                ...state,
                totalCount: action.payload
            }
        }
        default:
            return state
    }
}