import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { TRootState } from "../../../redux/store";
import { Modal } from "../../common/Modal/Modal";
import { IModal } from "../../common/Modal/types";
import { SimpleInput } from "../../common/Fields/SimpleInput/SimpleInput";
import { SimpleButton } from "../../common/Buttons/SimpleButton";
import { filterPhone, formatPhoneByPattern } from "../../../utils/inputHelpers";
import { iudUserAsync } from "../../../redux/reducers/adminUsers/actions";
import { IiudUser } from "../../../redux/reducers/adminUsers/actions/types";
import { openNotification } from "../../../utils/helpers";

const s = require('./style.module.scss');

interface IAddUserModalProps extends IModal {
    iudUserAsync: IiudUser
    iudUserLoading: boolean
    offset: number,
    count: number
}

const AddUserModal: React.FC<IAddUserModalProps> = ({
    open,
    closeHandler,
    iudUserAsync,
    iudUserLoading,
    offset,
    count,
}) => {

    const [name, setName] = useState<string>('')
    const [phone, setPhone] = useState<string>('')

    const createUserHandler = () => {
        if (!name) {
            openNotification({ type: "warning", message: 'Введите имя!' })
            return;
        }
        if (!phone) {
            openNotification({ type: "warning", message: "Введите телефонный номер!" })
            return;
        }
        iudUserAsync({
            flag: "I",
            name,
            login: filterPhone(phone)
        },
            () => null, offset, count)
    }
    return (
        <Modal open={open} closeHandler={closeHandler} title={'Добавить пользователя'}>
            <div className={s.simple_input_test}>
                <SimpleInput value={name}
                    onChange={e => setName(e.target.value)}
                    label={'ФИО'}
                    placeholder={'Введите ФИО'}
                />
            </div>
            <SimpleInput value={phone}
                onChange={e => setPhone(formatPhoneByPattern(e.target.value))}
                label={'Телефон'}
                placeholder={'Введите телефон'}
            />
            <SimpleButton className={s.btn_bottom}
                onClick={createUserHandler}
                loading={iudUserLoading}

            >
                Сохранить
            </SimpleButton>
        </Modal>

    )
}
const mapStateToProps = (state: TRootState) => ({
    iudUserLoading: state.adminUsers.iudUserLoading
})
const mapDispatchToProps = {
    iudUserAsync,
}

export const AddUserModalContainer = connect(mapStateToProps, mapDispatchToProps)(AddUserModal)