import React, {ChangeEvent} from "react";
import {Input, TextField} from "@material-ui/core";

const s = require('./style.module.scss')

interface ISimpleInputProps {
    value: string
    label?: string
    onChange: (e: ChangeEvent<HTMLInputElement>) => void

    [key: string]: any
}

export const SimpleInput: React.FC<ISimpleInputProps> = ({
                                                             value,
                                                             label,
                                                             onChange,
                                                             ...props
                                                         }) => {
    return (
        <TextField value={value}
                   onChange={onChange}
                   label={label}
                   className={s.simple_input}
                   size={'small'}
                   variant="outlined"
                   {...props}
        />
    )
}