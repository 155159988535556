import React, { useState } from "react";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { IUserObject } from "../../../../../redux/reducers/adminUsers/types.data";
import { Fade, IconButton } from "@material-ui/core";
import { userObjectListFlexes } from "../../../../../helpers/flexesHelpers";
import { EditObjectModalContainer } from "../../../Modals/EditObjectModal";
import { TSelDevicePart } from "../../../../../redux/api/requests/admin/type.data";
import { IIudDeviceContractor } from "../../../../../redux/reducers/adminUsers/actions/types";

const s = require('./cardStyles.module.scss');

export interface UserObjectListCardItemProps {
    userObject: IUserObject
    index: number
    userId: number
    searchPayload: TSelDevicePart
    iudDeviceContractorAsync: IIudDeviceContractor

    [key: string]: any
}

export const UserObjectListCardItem: React.FC<UserObjectListCardItemProps> = ({
    userObject,
    index,
    userId,
    searchPayload,
    iudDeviceContractorAsync,
    ...props
}) => {
    const {
        idContractor, idContractorPart, idPart,
        namePart, nameContractor, nameDevice,
        idSystem, numberDevice, idDevice,
        nameSystem, aliasDevice
    } = userObject

    const [editObjectModal, setEditObjectModal] = useState<boolean>(false)

    const removeDeviceHandler = () => {
        iudDeviceContractorAsync({
            flag: "D",
            idUser: userId,
            idPart,
        }, () => null, searchPayload)
    }
    return (
        <Fade in timeout={{ enter: 50 * index }}>
            <div id={`${idDevice}`} className={s.user_object}>
                <div className={s.item}>
                    <div className={s.title}>
                        Название
                    </div>
                    <div className={s.value}>
                        {nameDevice}
                    </div>
                </div>
                <div className={s.item}>
                    <div className={s.title}>
                        Панель
                    </div>
                    <div className={s.value}>
                        {nameSystem}
                    </div>
                </div>
                <div className={s.item}>
                    <div className={s.title}>
                        Пультовый номер
                    </div>
                    <div className={s.value}>
                        {numberDevice}
                    </div>
                </div>
                <div className={s.item}>
                    <div className={s.title}>
                        Группа
                    </div>
                    <div className={s.value}>
                        {namePart}
                    </div>
                </div>
                <div className={s.item}>
                    <div className={s.title}>
                        Псевдоним
                    </div>
                    <div className={s.value}>
                        {aliasDevice}
                    </div>
                </div>
                <div
                    className={s.row}>
                    <IconButton onClick={() => setEditObjectModal(true)}>
                        <EditIcon />
                    </IconButton>
                    <IconButton onClick={removeDeviceHandler}>
                        <DeleteIcon />
                    </IconButton>
                </div>
                {editObjectModal && <EditObjectModalContainer open={editObjectModal}
                    closeHandler={() => setEditObjectModal(false)}
                    object={userObject}
                    userId={userId}
                    searchPayload={searchPayload}
                />}
            </div>
        </Fade>
    )
}
