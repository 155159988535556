import React from "react";
import {ArrowRightRounded} from "@material-ui/icons";

export function itemRender(
    current: React.ReactNode,
    type: 'page' | 'prev' | 'next' | 'jump-prev' | 'jump-next',
    originalElement: React.ReactElement<HTMLElement>
) {
    if (type === 'prev') {
        return (
            <a>
                <ArrowRightRounded style={{transform: 'rotate(180deg)'}}/>
            </a>
        );
    }
    if (type === 'next') {
        return (
            <a>
                <ArrowRightRounded/>
            </a>
        );
    }
    return originalElement;
}