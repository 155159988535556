import clsx from "clsx";
import React from "react";
import {ICode} from "../../../redux/reducers/numCodes/type.data";
import {Loader} from "../Loader/Loader";
import {Code} from "./Code";
import {IiudVisibleCode} from "../../../redux/reducers/numCodes/action/types";


const s = require('./style.module.scss');

interface ICodesProps {
    codeList: ICode[]
    codeListLoading: boolean
    totalCount: number
    iudVisibleCodeAsync: IiudVisibleCode
}

export const Codes: React.FC<ICodesProps> = ({
                                                 codeList,
                                                 codeListLoading,
                                                 totalCount,
                                                 iudVisibleCodeAsync,
                                             }) => {
    return (
        <div className={s.codes}>
            <div className={clsx(s.codes_body, codeListLoading && s.reports_body_loading)}>
                {codeListLoading
                    ? <Loader/>
                    : codeList && codeList.length > 0 && codeList.map((value, index) =>
                    <Code key={value.code}
                          code={value}
                          index={index}
                          iudVisibleCodeAsync={iudVisibleCodeAsync}
                    />)}
            </div>
        </div>
    )
}