import {instance} from './../../../api/index';
import {
    setIUDUserLoading, setIudUserObjectLoading, setSystemsList, setSystemsListLoading,
    setTotalUserListCount, setTotalUserObjectListCount,
    setUserList,
    setUserListLoading, setUserObjectList, setUserObjectsListLoading,
} from './actions';
import {IRequesUserList, IiudUser, IIudDeviceContractor, ISelDevicePart} from './types';
import {checkError, openNotification} from "../../../../utils/helpers";


export const getUserListAsync: IRequesUserList = body => async (dispatch: any) => {
    await dispatch(setUserListLoading(true))
    await instance().close().getUserList(body)
        .then(res => {
            dispatch(setUserList(res.data?.events || []))
            dispatch(setTotalUserListCount(res.data?.totalcount || 0))
        })
        .catch(err => {
            checkError(err)
        })
        .finally(() => {
            dispatch(setUserListLoading(false))
        })
}

export const iudUserAsync: IiudUser = (body, onSuccess, offset, count) => async (dispatch: any) => {
    await dispatch(setIUDUserLoading(true))
    instance().close().iudUser({
        ...body,
        login: body?.login ? 'bit_' + body?.login : undefined
    })
        .then(res => {
            dispatch(getUserListAsync({
                offset: offset || 0,
                count: count || 15,
                searchValue: ''
            }))
            openNotification({
                type: 'success',
                message:'Успешно!'
            })
            onSuccess && onSuccess()
        })
        .catch(err => {
            checkError(err)
        })
        .finally(() => {
            dispatch(setIUDUserLoading(false))
        })
}

export const selDevicePartAsync: ISelDevicePart = payload => async (dispatch: any) => {
    await dispatch(setUserObjectsListLoading(true))
    await instance().close().selDevicePart(payload)
        .then(res => {
            dispatch(setUserObjectList(res.data?.devices || []))
            dispatch(setTotalUserObjectListCount(res.data?.totalcount || 0))
        })
        .catch(err => {
            checkError(err)
        })
        .finally(() => {
            dispatch(setUserObjectsListLoading(false))
        })
}
export const iudDeviceContractorAsync: IIudDeviceContractor = (payload, onSuccess, searchPayload) => async (dispatch: any) => {
    await dispatch(setIudUserObjectLoading(true))
    await instance().close().iudDeviceContractor(payload)
        .then(res => {
              openNotification({
                type: 'success',
                message:'Успешно!'
            })
            onSuccess && onSuccess()
            searchPayload && dispatch(selDevicePartAsync(searchPayload))
        })
        .catch(err => {
            checkError(err)
        })
        .finally(() => {
            dispatch(setIudUserObjectLoading(false))
        })
}
export const selSystemsAsync = () => async (dispatch: any) => {
    await dispatch(setSystemsListLoading(true))
    await instance().close().selSystems()
        .then(res => {
            console.log('selSystemsAsync')
            console.log(res)
            dispatch(setSystemsList(res.data?.data?.value || []))
        })
        .catch(err => {
            checkError(err)
        })
        .finally(() => {
            dispatch(setSystemsListLoading(false))
        })
}