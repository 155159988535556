import React, { FC, useEffect, useState } from "react";
import { IAdminLoginProps } from "./types";
import { TRootState } from "../../redux/store";
import { connect } from "react-redux";
import logo_name from "../../static/images/logo_name.png";
import { SimpleInput } from "../common/Fields/SimpleInput/SimpleInput";
import { Checkbox } from "@material-ui/core";
import { SimpleButton } from "../common/Buttons/SimpleButton";
import { requestLoginAsync } from "../../redux/reducers/auth/actions";
import { useKeyPress } from "../../hooks/useKeyPress";
import { Loader } from "../common/Loader/Loader";
import { log } from "util";

const s = require('./style.module.scss')

const AdminLogin: FC<IAdminLoginProps> = ({
    requestLoginAsync,
    loginLoading,
}) => {
    const [login, setLogin] = useState<string>(localStorage.getItem('username') || '')
    const [password, setPassword] = useState<string>(localStorage.getItem('password') || '')

    const [rememberMe, setRememberMe] = useState<boolean>(!!localStorage.getItem('username'))

    const enterPress = useKeyPress('Enter')

    const isMobile: boolean = window.innerWidth < 750

    useEffect(() => {
        if (enterPress) {
            requestLoginAsync({
                username: login,
                password: password
            })
        }
    }, [enterPress])

    useEffect(() => {
        if (rememberMe) {
            localStorage.setItem('username', login)
            localStorage.setItem('password', password)
        } else {
            localStorage.removeItem('username')
            localStorage.removeItem('password')
        }
    }, [rememberMe])

    return (
        <div className={s.content}>
            <div className={s.centred}>
                <div className={s.logo}>
                    <img src={logo_name} />
                </div>
                <div className={s.form}>
                    <SimpleInput value={login}
                        onChange={e => setLogin(e.target.value)}
                        label={'Логин'}
                        placeholder={'Логин'}
                        style={{ marginBottom: 16 }}
                    />
                    <SimpleInput value={password}
                        onChange={e => setPassword(e.target.value)}
                        label={'Пароль'}
                        placeholder={'Пароль'}
                        type={'password'}
                    />
                    <div>
                        <Checkbox value={rememberMe}
                            checked={rememberMe}
                            onChange={() => setRememberMe(!rememberMe)}
                        />
                        <span>Запомнить меня {!isMobile && 'на этом устройстве'}</span>
                    </div>
                    <SimpleButton
                        onClick={() => requestLoginAsync({
                            username: login,
                            password: password
                        })}
                        disabled={loginLoading}>
                        Войти
                    </SimpleButton>
                </div>
            </div>
            {loginLoading && <Loader fullScreen />}
        </div>
    )
}

const mapStateToProps = (state: TRootState) => ({
    loginLoading: state.auth.loadings.loginLoading
})
const mapDispatchToProps = {
    requestLoginAsync
}

export const AdminLoginContainer = connect(mapStateToProps, mapDispatchToProps)(AdminLogin)