import React, {createRef, useEffect, useRef} from "react";
import {connect} from "react-redux";
import {RemoteControllersContainer} from "./RemoteControllers/RemoteControllers";
import {InformationFeedContainer} from "./InformationFeed/InformationFeed";
import {useState} from "react";
import {useDragBlockHeight} from "../../hooks/useDragBlockHeight";
import { SimpleSwitch } from "../common/Fields/SimpleSwitch/SimpleSwitch";

const s = require('./style.module.scss')

const UserPanel = ({
    remoteControllers
}) => {
    
    const isMobile = window.innerWidth <=768

    const userPanelRef = createRef()
    const [totalHeight, setTotalHeight] = useState(0)
    useEffect(() => {
        if (userPanelRef.current) {
            setTotalHeight(userPanelRef.current.clientHeight - 20)
        }
    }, [userPanelRef.current])

    const [showObject, setShowObject] = useState(false)


    //Изначальн отрисовываем линию, высчитывая количество строк карточек и потом может перемещать линию
    const objectBlocksWidth = document.getElementById('objectCards')?.offsetWidth

    const REMOTE_CONTROLLER_CARD_WIDTH = 130;
    const REMOTE_CONTROLLER_CARD_HEIGHT = 130;

    const [remoteControllersHeight, setRemoteControllersHeight] = useState(550)

    useEffect(() => {
        let summaryCardWidth = 0
        if (!isMobile) {
            if (objectBlocksWidth) {
                if (remoteControllers?.length > 0) {
                    remoteControllers?.map(controllersGroup => {
                        summaryCardWidth = controllersGroup?.length * REMOTE_CONTROLLER_CARD_WIDTH + summaryCardWidth
                    })
                }
            }
        }
        if ((summaryCardWidth / objectBlocksWidth) % 1 === 0) {
            let initialHeight = (summaryCardWidth / objectBlocksWidth) * REMOTE_CONTROLLER_CARD_HEIGHT + 45 + 38 + 15 * (summaryCardWidth / objectBlocksWidth)
            setRemoteControllersHeight(initialHeight > 550 ? 550 : initialHeight)
        } else {
            let inititalHeight = ((summaryCardWidth / objectBlocksWidth) - ((summaryCardWidth / objectBlocksWidth) % 1) + 1) * REMOTE_CONTROLLER_CARD_HEIGHT + 45 + 38 + 15 * ((summaryCardWidth / objectBlocksWidth) - ((summaryCardWidth / objectBlocksWidth) % 1) + 1)
            setRemoteControllersHeight(inititalHeight > 550 ? 550 : inititalHeight)
        }

    }, [objectBlocksWidth, remoteControllers])
    

    const blockRef = useRef()
    const dragMode = useRef(false)


    const mouseDownHandler = () => {
        dragMode.current = true
    }
    const mouseUpHandler = () => {
        dragMode.current = false
    }
    const mouseMoveHandler = (event) => {
        if (blockRef.current && dragMode.current) {
        debugger
            setRemoteControllersHeight(value => value + event.movementY)
        }
    }

    useEffect(() => {
        if (blockRef.current) {
            document.addEventListener('mouseup', mouseUpHandler)
            document.addEventListener('mousemove', ev => mouseMoveHandler(ev))
            blockRef.current.addEventListener('mousedown', mouseDownHandler)
            return () => {
                document.removeEventListener('mouseup', mouseUpHandler)
                document.removeEventListener('mousemove', ev => mouseMoveHandler(ev))
                blockRef.current?.removeEventListener('mousedown', mouseDownHandler)
            }
        }
    }, [blockRef])



    return (
        <div className={s.user_panel}  ref={userPanelRef}>
            {isMobile &&   <SimpleSwitch checked={showObject}
                    onChange={() => setShowObject(!showObject)}
                    label={showObject ? 'Показать события' : 'Показать объекты'}
                />}
            {(!isMobile || showObject) && <RemoteControllersContainer height={isMobile ? '100%' : remoteControllersHeight}
                                        dragRef={blockRef}/>}
            {(!isMobile || !showObject) && <InformationFeedContainer height={isMobile ? '100%' : (totalHeight - remoteControllersHeight)} />}
        </div>
    )
}

const mapStateToProps = (state) => ({
    remoteControllers: state.remoteController.remoteControllers,
})
const mapDispatchToProps = {}

export const UserPanelContainer = connect(mapStateToProps, mapDispatchToProps)(UserPanel)
