import React from "react";
import clsx from "clsx";
import { ConfigProvider, DatePicker } from 'antd';
import LocaleProvider from "antd/lib/locale-provider";
import locale from "antd/lib/locale/ru_RU";
import moment from "moment";

const { RangePicker } = DatePicker;

const s = require('./style.module.scss')

interface IRangeDatePickerProps {
    onChange?: (values: any, formatString: [string, string]) => void;
    placeholder?: string
    value?: any
    maxWidth?: number
    [key: string]: any
}

export const RangeDatePicker: React.FC<IRangeDatePickerProps> = ({
    onChange,
    placeholder = 'Выберите дату',
    value,
    maxWidth,
    ...props
}) => {
    const classNames = clsx(
        s.simple_date_picker,
        props?.className
    )
    return (
        <RangePicker
            style={{ maxWidth }}
            value={value}
            size={'large'}
            className={classNames}
            onChange={onChange}
            allowClear
        />
    )
}

