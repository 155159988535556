import { ConfigProvider } from 'antd';
import React from 'react';
import {Provider} from "react-redux";
import {store} from "./redux/store";
import {RouterIndexContainer} from "./routers/RouterIndex";
import 'moment/locale/ru';
import ru_RU from "antd/lib/locale/ru_RU";

export const App = ({}) => {
    return (
        <Provider store={store}>
            <ConfigProvider locale={ru_RU}>
                <RouterIndexContainer/>
            </ConfigProvider>
        </Provider>
    );
}