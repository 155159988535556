import {ISetIsAuth, ISetLoginLoading} from "./types";
import {SET_AUTH, SET_LOGIN_LOADING} from "../types";

export const setIsAuth: ISetIsAuth = payload => ({
    type: SET_AUTH,
    payload,
})

export const setLoginLoading: ISetLoginLoading = payload => ({
    type: SET_LOGIN_LOADING,
    payload
})