import {IGetRemoteControllerGateways, IGetRemoteControllers, IRequestEditControllerAlias} from "./types";
import {setGatewayList, setGatewayListLoading, setRemoteControllers, setRemoteControllersLoading} from "./actions";
import {instance} from "../../../../api";
import {store} from "../../../../store";
import {checkError} from "../../../../../utils/helpers";

export const getRemoteControllersAsync: IGetRemoteControllers = (
    payload,
    onSuccess,
    onError) => async (dispatch: any) => {
    await dispatch(setRemoteControllersLoading(true))
    await instance().close().getRemoteControllersList(payload)
        .then(res => {
            dispatch(setRemoteControllers(res.data?.events || []))
            onSuccess && onSuccess()
        })
        .catch(err => {
            checkError(err)
            onError && onError()
        })
        .finally(() => {
            dispatch(setRemoteControllersLoading(false))
        })
}

export const getControllerGatewayListAsync: IGetRemoteControllerGateways = (
    payload,
    onSuccess,
    onError) => async (dispatch: any) => {
    await dispatch(setGatewayListLoading(true))
    await instance().close().getRemoteControllerGateways(payload)
        .then(res => {
            dispatch(setGatewayList(res.data))
            onSuccess && onSuccess()
        })
        .catch(err => {
            checkError(err)
            onError && onError()
        })
        .finally(() => {
            dispatch(setGatewayListLoading(false))
        })
}

export const editControllerAliasAsync: IRequestEditControllerAlias = (
    payload,
    onSuccess,
    onError) => async (dispatch: any) => {
    await instance().close().editControllerAlias(payload)
        .then(res => {
            dispatch(setRemoteControllers(store.getState().remoteController.remoteControllers.map(item => item.map(controller => ({
                ...controller,
                aliasDevice: controller.idDevice === payload.id
                    ? payload.alias
                    : controller.aliasDevice
            })))))
            onSuccess && onSuccess()
        })
        .catch(err => {
            checkError(err)
            onError && onError()
        })
}
