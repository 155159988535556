import {keys} from "./keys";
import {authRequests} from "./requests/auth/AuthRequests";
import {userPanelRequests} from "./requests/userPanel/userPanelRequests";
import {adminRequests} from "./requests/admin/adminRequests";
import {reportsRequests} from "./requests/reports/reports";

export const instance = () => {
    const mainKey = keys.main
    const token = localStorage.getItem(mainKey)
    return {
        open: () => ({
            ...authRequests().open(),
            ...userPanelRequests().open(),
            ...adminRequests().open(),
        }),
        close: () => ({
            ...authRequests().close(token),
            ...userPanelRequests().close(token),
            ...adminRequests().close(token),
            ...reportsRequests().close(token)
        }),
    }
}