import React, { FC } from "react";
import { ILog } from "../../../redux/reducers/logs/types.data";
import { Fade } from "@material-ui/core";
import { formatISOToDateString, formatISOToTimeString } from "../../../helpers/dateHelpers";
import { Tooltip } from "antd";

const s = require('./style.module.scss')

interface ILogProps {
    log: ILog,
    index: number
}

export const Log: FC<ILogProps> = ({
    log,
    index,

}) => {
    const { action, dataIn, dataOut, date, id, user } = log
    return (
        <Fade in timeout={{ enter: 100 * index }}>
            <div className={s.log}>
                <div style={{ flex: '0 0 8%' }}>
                    <div>{date && formatISOToDateString(date)}</div>
                    <div>{date && formatISOToTimeString(date)}</div>
                </div>
                <div style={{ flex: '0 0 10%' }}>
                    <Tooltip title={user} placement={"bottom"}>
                        {user}
                    </Tooltip>
                </div>
                <div style={{ flex: '0 0 10%' }}>
                    <Tooltip title={dataOut} placement={"bottom"}>
                        {dataOut && dataOut}
                    </Tooltip>
                </div>
                <div style={{ flex: '0 0 20%' }}>
                    <Tooltip title={action} placement={"bottom"}>
                        {action}
                    </Tooltip>
                </div>
                <div style={{ flex: '0 0 30%' }}>
                    <Tooltip title={dataIn} placement={"bottom"}>
                        {dataIn}
                    </Tooltip>
                </div>
            </div>
        </Fade>
    )
}