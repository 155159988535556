import React, { createRef, useEffect, useRef, useState } from 'react';
import { connect } from "react-redux";
import { SimpleButton } from "../../../common/Buttons/SimpleButton";
import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import { ROUTE_PATHS } from "../../../../routers/paths.main";
import { SimpleInput } from "../../../common/Fields/SimpleInput/SimpleInput";
import { searchLabel, searchPlaceholderAdminObject } from "../../../../helpers/mockHelpers";
import { SearchField } from "../../../common/SearchField/SearchField";
import clsx from "clsx";
import { IUserObject } from "../../../../redux/reducers/adminUsers/types.data";
import { iudUserAsync, selDevicePartAsync } from "../../../../redux/reducers/adminUsers/actions";

import { IiudUser, ISelDevicePart } from "../../../../redux/reducers/adminUsers/actions/types";
import { TRootState } from "../../../../redux/store";
import { UserObjectsContainer } from "./ObjectList/UserObjectList";

import { AddObjectModalContainer } from '../../Modals/AddObjectModal';
import { SimplePagination } from "../../../common/SimplePagination/Pagination";
import { SimpleButtonUp } from '../../../common/SimpleButtonUp/SimpleButttonUp';
import { filterPhone, formatPhoneByPattern } from "../../../../utils/inputHelpers";
import { ArrowIcon } from '../../../../icons/icon';
import { isMobile } from '../../../../helpers/mobileHelpers';
import { Collapse, Fade, IconButton } from '@material-ui/core';

const s = require('./style.module.scss');

export interface ManagementAdminUsersProps {
    userObjectList: IUserObject[]
    userObjectListLoading: boolean
    totalUserObjectListCount: number
    iudUserAsync: IiudUser
    selDevicePartAsync: ISelDevicePart

    [key: string]: any
}

const ManagementAdminUsers: React.FC<ManagementAdminUsersProps> = ({
    userObjectList,
    userObjectListLoading,
    totalUserObjectListCount,
    iudUserAsync,
    selDevicePartAsync,
    ...props
}) => {
    const PAGE_SIZE = 8
    ////////    url и параметры    ///////
    const locationState: any = useLocation().state
    const match = useRouteMatch<{ id: string }>()
    ////////    url и параметры    ///////

    ////////    локальное состояние    ///////
    const [name, setName] = useState<string>(locationState?.name)
    const [phone, setPhone] = useState<string>(locationState?.phone || '')
    const [addObjectModal, setAddObjectModal] = useState<boolean>(false)
    const [currentPage, setCurrentPage] = useState<number>(1)

    const [showObjectButton, setShowObjectButton] = useState<boolean>(!isMobile)
    const [showUserButton, setShowUserButton] = useState<boolean>(!isMobile)
    ////////    локальное состояние    ///////

    ////////    хэндлеры    ///////
    const removeUserHandler = () => {
        if (!match.params.id) {
            return
        }
        iudUserAsync({
            flag: "D",
            id: +match.params.id,
            login: filterPhone(phone),
        },
            () => {
                window.location.pathname = ROUTE_PATHS.admin.users(1).path
            })
    }

    const editUserHandler = () => {
        if (!name || !phone || !match.params.id) {
            return
        }
        iudUserAsync({
            flag: "U",
            name,
            login: filterPhone(phone),
            id: +match.params.id,
        })
    }
    ////////    хэндлеры    ///////

    ////////    сайд-эффекты    ///////
    useEffect(() => {
        selDevicePartAsync({
            idUser: +match.params.id,
            offset: (currentPage - 1) * PAGE_SIZE,
            count: PAGE_SIZE,
        })
    }, [currentPage])
    ////////    сайд-эффекты    ///////

    return (
        <div className={clsx(s.body, s.title)}>
            <Link to={ROUTE_PATHS.admin.users(1).path}>
                <SimpleButton style={{ background: '#343a40' }}
                    size='small'
                    onClick={() => null}>
                    Список всех пользователей
                </SimpleButton>
            </Link>

            <div className={s.user_controller}>
                <div className={s.user_name}>{name}</div>
                <div className={s.user_phone}>{phone && formatPhoneByPattern(phone)}</div>
                {isMobile && <span className={showUserButton ? s.arrow_object_active : s.arrow_object}
                    onClick={() => setShowUserButton(!showUserButton)}>
                    <ArrowIcon />
                </span>}
            </div>
            <Collapse style={{ minHeight: 'auto' }} in={showUserButton}>
                <div className={s.simple_input_test}>
                    <SimpleInput
                        value={name}
                        onChange={e => setName(e.target.value)}
                        label={'ФИО'}
                    />
                </div>
                {/* <SimpleInput value={formatPhoneByPattern(phone)}
                             onChange={e => setPhone(e.target.value)}
                label={'Телефон'}
                /> */}
                <div className={s.btn_block}>
                    <SimpleButton style={{ background: '#00398b', opacity: '0.90', marginRight: '1%' }}
                        size='medium'
                        onClick={editUserHandler}>
                        Сохранить
                    </SimpleButton>
                    <SimpleButton style={{ background: '#b9201d', opacity: '0.90' }}
                        size='medium'
                        onClick={removeUserHandler}>
                        Удалить пользователя
                    </SimpleButton>
                </div>
            </Collapse>
            <h3 className={s.title}>
                Объекты
                {isMobile && <span className={showObjectButton ? s.arrow_object_active : s.arrow_object}
                    onClick={() => setShowObjectButton(!showObjectButton)}>
                    <ArrowIcon />
                </span>}
            </h3>
            <Collapse style={{ minHeight: 'auto' }} in={showObjectButton}>
                <SimpleButton className={s.add_object_btn} style={{ background: '#00398b' }}
                    size='medium'
                    color='secondary'
                    onClick={() => setAddObjectModal(true)}
                >
                    Добавить объект
                </SimpleButton>
                <SearchField label={searchLabel}
                    placeholder={searchPlaceholderAdminObject}
                    withClear
                    onSearch={(value: string) => {
                        setCurrentPage(1)
                        selDevicePartAsync({
                            idUser: +match.params.id,
                            text: value || undefined,
                            offset: 0,
                            count: PAGE_SIZE,
                        })
                    }}
                    className={s.search_user_object_list}
                />
            </Collapse>
            <UserObjectsContainer userId={match.params.id}
                searchPayload={{
                    idUser: +match.params.id,
                    offset: (currentPage - 1) * PAGE_SIZE,
                    count: PAGE_SIZE,
                }}
            />
            <div>
                <SimplePagination currentPage={currentPage}
                    total={totalUserObjectListCount}
                    onChange={page => setCurrentPage(page)}
                    pageSize={PAGE_SIZE}
                    className={s.pagination}
                />
            </div>

            <AddObjectModalContainer open={addObjectModal}
                closeHandler={() => setAddObjectModal(false)}
                searchPayload={{
                    idUser: +match.params.id,
                    offset: (currentPage - 1) * PAGE_SIZE,
                    count: PAGE_SIZE,
                }}
                userId={+match.params.id}
            />
        </div>
    )
}

const mapStateToProps = (state: TRootState) => ({
    userObjectList: state.adminUsers.userObjectsList,
    userObjectListLoading: state.adminUsers.userObjectsListLoading,
    totalUserObjectListCount: state.adminUsers.totalUserObjectListCount,
})
const mapDispatchToProps = {
    iudUserAsync,
    selDevicePartAsync,
}

export const ManagementAdminUsersContainer = connect(mapStateToProps, mapDispatchToProps)(ManagementAdminUsers)
