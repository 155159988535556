import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'antd/dist/antd.css';
import { App } from './App';

import reportWebVitals from './reportWebVitals';
import ruRU from 'antd/lib/locale-provider/ru_RU';
import { ConfigProvider } from "antd";


ReactDOM.render(
  <React.StrictMode>
    <ConfigProvider locale={ruRU}>
      <App />
    </ConfigProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
