import React, {memo, useEffect, useRef, useState} from "react";
import {store} from "../../../redux/store";
import {connect, useDispatch} from "react-redux";
import {getInfoFeedListAsync,} from "../../../redux/reducers/userPanel/informationFeed/action/index";
import {Reports} from "../../common/Reports/Reports";
import {setInfoFeedList, setShowTestEvents, setTotalInfoFeedCount} from "../../../redux/reducers/userPanel/informationFeed/action/action";
import {SimplePagination} from "../../common/SimplePagination/Pagination";
import {ROUTE_PATHS} from "../../../routers/paths.main";
import {useHistory, useRouteMatch} from "react-router-dom";
import {HubConnectionBuilder} from "@microsoft/signalr";
import {keys} from "../../../redux/api/keys";
import {openNotification} from "../../../utils/helpers";
import {instance} from "../../../redux/api";
import {RangeDatePicker} from "../../common/Fields/RangeDatePicker/RangeDatePicker";
import {SearchField} from "../../common/SearchField/SearchField";
import {infoFeedSearchPlaceholder, searchLabel} from "../../../helpers/mockHelpers";
import {SimpleSwitch} from "../../common/Fields/SimpleSwitch/SimpleSwitch";
import alarm from './alarm.mp3'
import useSound from "use-sound";
import { isMobile } from "../../../helpers/mobileHelpers";
import { DatePicker } from "antd";
import moment from "moment";

const s = require('./style.module.scss')

const InformationFeed = memo(({
                                                                   height,
                                                                   infoFeedList,
                                                                   infoFeedListLoading,
                                                                   totalCount,
                                                                   getInfoFeedListAsync,
                                                                   showTestEvents,
                                                                   setShowTestEvents,
                                                               }) => {
    const PAGE_SIZE = 15

    const dispatch = useDispatch()
    const history = useHistory()
    const match= useRouteMatch()
    const [play] = useSound(alarm);

    ////    Локальный state   ////
    const [currentPage, setCurrentPage] = useState(0)

   const [startDate, setStartDate] = useState(moment(new Date()))
    const [endDate, setEndDate] = useState(moment(startDate).add(1, 'd'))
    const [searchValue, setSearchValue] = useState()

    const [feedScrollTop, setFeedScrollTop] = useState(0)
    ////    Локальный state   ////

    ////    Эффекты   ////
    useEffect(() => {

        const connection = new HubConnectionBuilder()
            .withUrl('https://wg.neva-online.ru/SurGard', {
                accessTokenFactory: () => {
                    const main = keys.main
                    return localStorage.getItem(main) || instance().open().authorize({
                        password: '',
                        username: ''
                    }).then(res => res.data.access_token)
                }
            })
            .withAutomaticReconnect()
            .build();


        !showTestEvents && connection?.start()
            .then(res => {
                openNotification({type: "success", message: 'Connected!'})
            })
            .catch(err => {
                // openNotification({type: "error", message: 'Connection failed: ' + err})
            })


        connection.on('Receive', data => {
            play()

            const currentState = {
                infoFeedList: store.getState().informationFeed.infoFeedList.filter((item, index) => index < 14),
                totalCount: store.getState().informationFeed.totalCount
            }
            const newState = {
                infoFeedList: JSON.parse(data)?.events || [],
            }

            if (newState.infoFeedList.find((item) => item?.isAlarm)) {
            }

            if (newState.infoFeedList?.length > 0) {
                dispatch(setInfoFeedList([...newState.infoFeedList, ...currentState.infoFeedList]));
                dispatch(setTotalInfoFeedCount(currentState.totalCount + newState.infoFeedList.length))
            }
        });

        return () => {
            connection.stop()
        }
    }, [showTestEvents])

    useEffect(() => {
        if (startDate && endDate) getInfoFeedListAsync({
            dateBeg: startDate?.toDate()?.toISOString() || undefined,
            dateEnd: endDate?.toDate()?.toISOString() || undefined,
            count: 15,
            offset: (+match?.params?.page - 1) * PAGE_SIZE,
            text: searchValue || undefined,
            isTest: showTestEvents
        })
        setCurrentPage(+match?.params?.page)

    }, [match])

    useEffect(() => {
        if (startDate && endDate || searchValue) {
            getInfoFeedListAsync({
                dateBeg: startDate?.toDate()?.toISOString() || undefined,
                dateEnd: endDate?.toDate()?.toISOString() || undefined,
                count: 15,
                offset: (+match?.params?.page - 1) * PAGE_SIZE,
                text: searchValue || undefined,
                isTest: showTestEvents,
                searchValue: searchValue
            })
        }
    }, [startDate, endDate, searchValue, showTestEvents])

    ////    Эффекты   ////

    //// Функции ////
    const clearHandler = () => {
        setSearchValue(null)
        setStartDate(null)
        setEndDate(null)
        getInfoFeedListAsync({
            count: 15,
            offset: (+match?.params?.page - 1) * PAGE_SIZE,
            isTest: showTestEvents,
        })
    }
    //// Функции ////

    return (
        <div className={s.information_feed} style={{height}}
             onScroll={event => setFeedScrollTop(event.currentTarget.scrollTop)}>

            <div className={s.information_feed__wrapper}>
                <div className={s.information_feed_filter}>
                    
                    <RangeDatePicker
                        value={[startDate, endDate] }
                        onChange={(values, formatString) => {
                        if (values && values?.length > 0) setStartDate(values[0] || setStartDate(null))
                        else setStartDate(null)
                        if (values && values?.length > 1) setEndDate(values[1] || setEndDate(null))
                        else setEndDate(null)
                    }}
                    />
                    <SearchField 
                                 onSearch={(value) => setSearchValue(value)}
                                 value={searchValue || ""}
                                 label={searchLabel}
                                 placeholder={infoFeedSearchPlaceholder}
                                 withClear
                        onClear={clearHandler}
                    />
                    <SimpleSwitch label={'Тестовые события'}
                                  checked={showTestEvents}
                                  onChange={() => setShowTestEvents(!showTestEvents)}
                    />
                </div>

                <Reports reportsList={infoFeedList?.filter((item, index) => index <= PAGE_SIZE - 1)}
                         reportsListLoading={infoFeedListLoading}
                         totalCount={totalCount}
                         feedScrollTop={feedScrollTop}
                />

                <SimplePagination total={totalCount}
                                  onChange={page => history.push(ROUTE_PATHS.main(page).path)}
                                  pageSize={PAGE_SIZE}
                                  currentPage={currentPage}
                                  size={isMobile && 'small'}
                />
            </div>
        </div>
    )
})

const mapStateToProps = (state) => ({
    infoFeedList: state.informationFeed.infoFeedList,
    infoFeedListLoading: state.informationFeed.infoFeedListLoading,
    totalCount: state.informationFeed.totalCount,
    showTestEvents: state.informationFeed.showTestEvents,
})
const mapDispatchToProps = {
    getInfoFeedListAsync,
    setInfoFeedList,
    setShowTestEvents,
}

export const InformationFeedContainer = connect(mapStateToProps, mapDispatchToProps)(InformationFeed)
