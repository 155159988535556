import React, {useState} from 'react';
import {connect} from 'react-redux';
import {IiudVisibleCode, IRequestCodeList} from '../../../redux/reducers/numCodes/action/types';
import {ICode} from '../../../redux/reducers/numCodes/type.data';
import {TRootState} from '../../../redux/store';
import {PageTitle} from "../../common/PageTitle/PageTitle";
import {getCodesListAsync, iudVisibleCodeAsync} from '../../../redux/reducers/numCodes/action';
import {Codes} from '../../common/NumCodes/Codes';
import {SimpleButton} from "../../common/Buttons/SimpleButton"
import {SimpleInput} from "../../common/Fields/SimpleInput/SimpleInput";
import {searchLabel, searchPlaceholderNumCodes} from "../../../helpers/mockHelpers";
import {SearchField} from '../../common/SearchField/SearchField';
import clsx from 'clsx';
import {useDebounce} from "../../../hooks/useDebounce";

const s = require('./style.module.scss');

export interface SomeProps {
    codeList: ICode[]
    codeListLoading: boolean
    totalCount: number
    getCodesListAsync: IRequestCodeList
    iudVisibleCodeAsync: IiudVisibleCode

    [key: string]: any
}

const AdminSettings: React.FC<SomeProps> = ({
                                                codeList,
                                                codeListLoading,
                                                getCodesListAsync,
                                                iudVisibleCodeAsync,
                                                totalCount,
                                            }) => {

    const getCodeListHandler = useDebounce((text?: string) => {
        getCodesListAsync({text: text || undefined})
    }, 300)
    const [kod, setKod] = useState<string>('')
    return (
        <div className={s.codes_page}>
            <PageTitle title={'Редактор кодов!'}/>
            <div className={s.main_content}>
                <div className={clsx(s.main_content_column_items, s.first_column)}>
                    <div className={s.item_first_column}>
                        <SimpleInput value={kod}
                                     onChange={e => setKod(e.target.value)}
                                     label={'Код'}
                                     placeholder={'Введите код'}
                                     size='small'
                        />
                    </div>
                    <div className={s.item_first_column}>
                        <SimpleButton className={s.add_btn_code}
                                      size='small'
                                      onClick={() => {
                                          if (kod) {
                                              iudVisibleCodeAsync({
                                                  flag: "I",
                                                  code: kod
                                              })
                                          }
                                      }}>
                            Добавить
                        </SimpleButton>
                    </div>
                </div>
                <div className={clsx(s.main_content_column_items, s.second_column)}>
                    <div className={clsx(s.item, s.first_item_second_column)}>
                        <SearchField label={searchLabel}
                                     placeholder={searchPlaceholderNumCodes}
                                     withClear
                                     onSearch={(value: string) => getCodeListHandler(value)}
                        />
                    </div>
                    <div className={clsx(s.item, s.second_column_codes_wrap)}>
                        <Codes codeList={codeList}
                               codeListLoading={codeListLoading}
                               totalCount={totalCount}
                               iudVisibleCodeAsync={iudVisibleCodeAsync}
                        />
                    </div>
                </div>
            </div>

        </div>
    )
}

const mapStateToProps = (state: TRootState) => ({
    codeList: state.codes.codeList,
    codeListLoading: state.codes.codeListLoading,
    totalCount: state.codes.totalCount
})
const mapDispatchToProps = {
    getCodesListAsync,
    iudVisibleCodeAsync,
}

export const AdminSettingsContainer = connect(mapStateToProps, mapDispatchToProps)(AdminSettings)