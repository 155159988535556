import React from "react";
import {Switch} from "@material-ui/core";

const s = require('./style.module.scss')

interface ISimpleSwitchProps {
    checked: boolean
    onChange: any
    label?: string
    color?: "primary" | "secondary" | "default"

    [key: string]: any
}

export const SimpleSwitch: React.FC<ISimpleSwitchProps> = ({
                                                                 checked,
                                                                 onChange,
                                                                 label,
                                                                 color = 'primary',
                                                                 ...props
                                                             }) => {
    return (
        <div className={s.simple_switch}>
            <Switch checked={checked} value={checked} onChange={onChange} color={color} {...props}/>
            {label && <div className={s.simple_switch_title}>{label}</div>}
        </div>
    )
}