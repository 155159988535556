import React, {useEffect, useState} from "react";
import {Select} from "antd";
import {TEnumItem} from "../../../../redux/globalTypes";

const s = require('./style.module.scss')

interface IMultipleSelectProps {
    selectedItems: (number | string)[]
    onChange: any
    items: TEnumItem[]

    [key: string]: any
}

export const MultipleSelect: React.FC<IMultipleSelectProps> = ({
                                                                   selectedItems,
                                                                   onChange,
                                                                   items,
                                                                   ...props
                                                               }) => {
    // const filteredOptions = items.filter(item => {
    //     const isExisted = !!selectedItems.find((selectedItem: number | string) => selectedItem === item.id)
    //     return !isExisted
    // })
    return (
        <Select
            maxTagCount={1}
            mode="multiple"
            value={selectedItems}
            onChange={onChange}
            className={s.multiple_select}
            style={{width: '100%',flexWrap:'nowrap'}}
            size={"large"}
            filterOption={(input, option) => {
                return option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }}
            {...props}
        >
            {items.map(item => (
                <Select.Option key={item.id} value={item.id}>
                    {item.name}
                </Select.Option>
            ))}
        </Select>
    )
}