import React, {FC} from 'react';
import {createRef} from 'react';
import {SimpleButton} from '../Buttons/SimpleButton';

const s = require('./style.module.scss');

export const SimpleButtonUp: FC<{ onClick: any}> = ({onClick}) => {

    return (
        <div className={s.footer}>
            <SimpleButton onClick={onClick}
                          size='medium'
                          className={s.btn_up}>
                Вверх
            </SimpleButton>
        </div>
    )
}
