import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { SimpleInput } from "../Fields/SimpleInput/SimpleInput";
import { SimpleButton } from "../Buttons/SimpleButton";
import { useDebounce } from "../../../hooks/useDebounce";

const s = require('./style.module.scss')

interface ISearchFieldProps {
    onSearch: Function
    adPayload?: any

    withClear?: boolean
    onClear?: Function
    placeholder?: string
    label?: string
    value?: string

    [key: string]: any
}

export const SearchField: React.FC<ISearchFieldProps> = ({
    onSearch,
    adPayload,

    withClear,
    onClear,

    placeholder,
    label,
    value,
    ...props
}) => {
    const DEBOUNCED_VALUE = 200
    const onSearchDebounced = useDebounce((value: string) => {
        onSearch(value)
    }, DEBOUNCED_VALUE)

    const [searchValue, setSearchValue] = useState<string>(value || '')
    useEffect(() => {
        onSearchDebounced(searchValue)
    }, [searchValue])

    const isMobile: boolean = window.innerWidth < 750

    return (
        <div className={clsx(s.search_field, props?.className && props.className)}>
            <SimpleInput label={label}
                placeholder={placeholder}
                value={searchValue}
                onChange={e => setSearchValue(e.target.value)}
            />
            {withClear && <SimpleButton className={s.search_field_clear} size={isMobile ? 'small' : 'large'} onClick={() => {
                setSearchValue('')
                onClear && onClear()
            }}>
                Очистить поиск
            </SimpleButton>}
        </div>
    )
}
