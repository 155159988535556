import React from "react";
import {Tooltip} from "antd";

interface ISimpleTooltipProps {
    title: string
    active: boolean
    placement: 'right'
        | 'bottom'
        | 'topLeft'
        | 'topRight'
        | 'bottomLeft'
        | 'bottomRight'
        | 'leftTop'
        | 'leftBottom'
        | 'rightTop'
        | "rightBottom"

    [key: string]: any
}

export const SimpleTooltip: React.FC<ISimpleTooltipProps> = ({
                                                                 title,
                                                                 placement,
                                                                 active,
                                                                 ...props
                                                             }) => {
    if (!active) return <div>{props.children}</div>
    return (
        <Tooltip title={title} placement={placement} {...props}/>
    )
}