import {
    SET_USER_LIST_LOADING,
    SET_TOTAL_USER_LIST_COUNT,
    SET_SELECTED_USER,
    SET_USER_OBJECT_LIST,
    SET_USER_OBJECT_LIST_LOADING,
    SET_TOTAL_USER_OBJECT_LIST_COUNT,
    SET_IUD_USER_LOADING,
    SET_SYSTEMS_LIST,
    SET_SYSTEMS_LIST_LOADING,
    SET_IUD_USER_OBJECT_LOADING
} from './../types';
import { SET_USER_LIST } from '../types';
import {
    ISetIUDUserLoading, ISetIudUserObjectLoading,
    ISetSelectedUser, ISetSystemsList, ISetSystemsListLoading,
    ISetTotalUserListCount, ISetTotalUserObjectListCount,
    ISetUserList,
    ISetUserListLoading, ISetUserObjectLoading, ISetUserObjectsList,
} from './types';

export const setUserList:ISetUserList = payload => ({
    type: SET_USER_LIST,
    payload
})
export const setUserListLoading:ISetUserListLoading = payload => ({
    type: SET_USER_LIST_LOADING,
    payload
})
export const setTotalUserListCount:ISetTotalUserListCount = payload => ({
    type: SET_TOTAL_USER_LIST_COUNT,
    payload
})
export  const setSelectedUser:ISetSelectedUser = payload => ({
    type: SET_SELECTED_USER,
    payload
})
export  const setUserObjectList:ISetUserObjectsList = payload => ({
    type: SET_USER_OBJECT_LIST,
    payload
})
export const setUserObjectsListLoading: ISetUserObjectLoading = payload => ({
    type: SET_USER_OBJECT_LIST_LOADING,
    payload
})
export const setTotalUserObjectListCount: ISetTotalUserObjectListCount = payload => ({
    type: SET_TOTAL_USER_OBJECT_LIST_COUNT,
    payload
})
export const setIUDUserLoading: ISetIUDUserLoading = payload => ({
    type: SET_IUD_USER_LOADING,
    payload
})
export const setIudUserObjectLoading: ISetIudUserObjectLoading = payload => ({
    type: SET_IUD_USER_OBJECT_LOADING,
    payload
})
export const setSystemsList: ISetSystemsList = payload => ({
    type: SET_SYSTEMS_LIST,
    payload
})
export const setSystemsListLoading: ISetSystemsListLoading = payload => ({
    type: SET_SYSTEMS_LIST_LOADING,
    payload
})