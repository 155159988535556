import React from "react";

const s = require('./style.module.scss')

interface IAnimatedItemProps {
    onClick?: () => void
}

export const AnimatedItem: React.FC<IAnimatedItemProps> = ({
    children,
    onClick
}) => {
    return (
        <div onClick={onClick} className={s.anim}>
            {children}
        </div>
    )
}