import React, {useEffect, useState} from "react";
import {IModal} from "../../../common/Modal/types";
import {Modal} from "../../../common/Modal/Modal";
import {IRemoteController} from "../../../../redux/reducers/userPanel/remoteControllers/types.data";
import {TRootState} from "../../../../redux/store";
import {connect} from "react-redux";
import {getControllerGatewayListAsync} from "../../../../redux/reducers/userPanel/remoteControllers/actions";
import {IGetRemoteControllerGateways} from "../../../../redux/reducers/userPanel/remoteControllers/actions/types";
import {CreateCardField} from "./common/CreateCardField";
import {IconButton} from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import {ArrowDropDown} from "@material-ui/icons";
import clsx from "clsx";
import {GatewayListContainer} from "../GatewatList/GatewayList";
import {EditPseudonymModalContainer} from "./EditPseudonym";
import {ReportsPageContainer} from "../../../Reports/ReportsPage";
import {isMobile } from '../../../../helpers/mobileHelpers'
const s = require('./style.module.scss')

interface IControllerCardModalProps extends IModal {
    controller: IRemoteController | null
    controllerGatewaysListLoading: boolean
    getControllerGatewayListAsync: IGetRemoteControllerGateways
}

const ControllerCardModal: React.FC<IControllerCardModalProps> = ({
                                                                      open,
                                                                      closeHandler,

                                                                      controller,
                                                                      controllerGatewaysListLoading,
                                                                      getControllerGatewayListAsync,
                                                                  }) => {

    useEffect(() => {
        if (controller && open) getControllerGatewayListAsync({id: controller?.idDevice})
    }, [open])

    const {
        connection, accountNumberSite, adressSite,
        aliasDevice, idDevice, idPart,
        status, nameDevice, namePart, test,
    } = {...controller}

    const [gatewaysOpen, setGatewaysOpen] = useState<boolean>(false)
    const [editPseudonymModal, setEditPseudonymModal] = useState<boolean>(false)
    console.log(isMobile)
    return (
        <Modal open={open}
               closeHandler={closeHandler}
               title={'Карточка объекта'}
               loading={controllerGatewaysListLoading}
               fullWidth
               style={isMobile ? {width:'300px'} : {}}
               >
            <section className={s.body} style={isMobile ? {width:'300px'} : {}}>
                <CreateCardField label={'Псевдоним'}>
                    <IconButton style={{padding: '4px'}} onClick={() => setEditPseudonymModal(true)}>
                        <EditIcon/>
                    </IconButton>
                    <span>{aliasDevice}</span>
                </CreateCardField>

                <CreateCardField label={'Название'}>
                    <span>{nameDevice}</span>
                </CreateCardField>

                <CreateCardField label={'Адрес'}>
                    <span>{adressSite}</span>
                </CreateCardField>

                <CreateCardField label={'Пультовый номер'}>
                    <span>{accountNumberSite}</span>
                </CreateCardField>

                <CreateCardField label={'Группа'}>
                    <span>{namePart}</span>
                </CreateCardField>

                <CreateCardField label={'Статус'} className={s.white_text}>
                    <span style={{background: `#${status?.color || "fff"}`}}>{status?.isStateArmSite || '-'}</span>
                </CreateCardField>

                {/*<CreateCardField label={'Связь'} className={s.white_text}>*/}
                {/*    {connection && connection?.length > 0 && connection.map(item => <span*/}
                {/*        style={{background: item.color}}>{item.name}</span>)}*/}
                {/*</CreateCardField>*/}

                <CreateCardField label={'Связь'} className={s.white_text}>
                    <span style={{background: `#${connection?.gprsColor || "fff"}`}}>{connection?.gprsText}</span>
                    <span style={{background: `#${connection?.lanColor || "fff"}`}}>{connection?.lanText}</span>
                </CreateCardField>

                <CreateCardField label={'Внутренний тест'} className={s.white_text}>
                    <span style={{background: test ? '#00a338' : '#6c757d'}}>
                        {test
                            ? 'прошёл'
                            : 'не прошёл'}
                    </span>
                </CreateCardField>

                {/*<CreateCardField label={'Внутренний тест'} className={s.white_text}>*/}
                {/*    <span>Нет данных</span>*/}
                {/*</CreateCardField>*/}

                <CreateCardField label={'Шлейфы'}>
                    <div className={clsx(s.gateway, gatewaysOpen && s.gateway_open)}
                         onClick={() => setGatewaysOpen(!gatewaysOpen)}>
                        <div>{gatewaysOpen ? 'Свернуть' : 'Развернуть'}</div>
                        <ArrowDropDown/>
                    </div>
                </CreateCardField>

                {gatewaysOpen && <GatewayListContainer/>}

                <CreateCardField label={'Отчёты'}>
                </CreateCardField>

                <ReportsPageContainer isPage={false}/>
            </section>

            <EditPseudonymModalContainer open={editPseudonymModal}
                                         closeHandler={() => setEditPseudonymModal(false)}
                                         pseudonymValue={aliasDevice || ''}
                                         controllerId={idDevice || ''}
            />
        </Modal>
    )
}

const mapStateToProps = (state: TRootState) => ({
    controller: state.remoteController.selectedRemoteController,
    controllerGatewaysListLoading: state.remoteController.controllerGatewaysListLoading,
})
const mapDispatchToProps = {
    getControllerGatewayListAsync,
}

export const ControllerCardModalContainer = connect(mapStateToProps, mapDispatchToProps)(ControllerCardModal)
