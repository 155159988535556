import JwtDecode from 'jwt-decode'
import {TAuthBody, TLoginSms, TSendSms} from "./types";
import {instanceAxiosClose, instanceAxiosOpen} from "../../instance.axios";
import {paths} from "../../paths";
import {keys} from "../../keys";

export const authRequests = () => ({
    open: () => ({
        authorize: (body: TAuthBody) => {
            return instanceAxiosOpen().post(paths.login, {
                ...body,
                username: body.username
            })
        },
        sendSms: (body: TSendSms) => {
            return instanceAxiosOpen().post(paths.sendSms, {
                ...body,
                username: 'bit_' + body.username
            })
        },
        loginSms: (body: TLoginSms) => {
            return instanceAxiosOpen().post(paths.loginSms, {
                ...body,
                username: 'bit_' + body.username
            })
        },
    }),
    close: (token: string | null) => ({
        selCurrentContractor: () => {
            return instanceAxiosClose(token).post(paths.selCurrentContractor, {})
        },

        refresh: () => {
            const refreshKey = keys.refresh
            const refreshToken = localStorage.getItem(refreshKey)
            if (token) {
                const currentTime = new Date().getTime() / 1000
                const decode: { exp: number } = JwtDecode(token)
                if (currentTime > decode.exp) {
                    return instanceAxiosClose(refreshToken).get(paths.refreshLogin)
                } else {
                    return {
                        data: {
                            token: true
                        }
                    }
                }
            }
        }
    }),
})