import React from 'react';

const s = require('./style.module.scss');

interface IHeadComponentProps {
    title: string
    [key: string]: any
}

export const PageTitle: React.FC<IHeadComponentProps> = ({
                                                             title,
                                                         }) => {
    return (
        <div className={s.title}>
            {title}
        </div>
    );
};

