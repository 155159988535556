import {AUTH_ACTIONS, SET_AUTH, SET_LOGIN_LOADING, TAuthReducer} from "./types";

const init: TAuthReducer = {
    isAuth: false,
    loadings: {
        loginLoading: false
    }
}

export const authReducer = (state = init, action: AUTH_ACTIONS) => {
    switch (action.type) {
        case SET_AUTH: {
            return {
                ...state,
                isAuth: action.payload
            }
        }
        case SET_LOGIN_LOADING: {
            return {
                ...state,
                loadings: {
                    ...state.loadings,
                    loginLoading: action.payload
                }
            }
        }
        default:
            return state
    }
}