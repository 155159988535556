import {
    INFORMATION_FEED_ACTIONS,
    SET_INFO_FEED_LIST,
    SET_INFO_FEED_LIST_LOADING,
    SET_INFORMATION_FEED_SEARCH_VALUE,
    SET_SHOW_TEST_EVENTS,
    SET_TOTAL_INFO_FEED_COUNT,
    TInformationFeedReducer,
} from './types';

const init: TInformationFeedReducer = {
    infoFeedList: [],
    infoFeedListLoading: false,
    totalCount: 0,
    showTestEvents: false,
    searchValue: ''
}

export const informationFeedReducer = (state = init, action: INFORMATION_FEED_ACTIONS) => {
    switch (action.type) {
        case SET_INFO_FEED_LIST: {
            return {
                ...state,
                infoFeedList: action.payload
            }
        }
        case SET_INFO_FEED_LIST_LOADING: {
            return {
                ...state,
                infoFeedListLoading: action.payload
            }
        }
        case SET_TOTAL_INFO_FEED_COUNT: {
            return {
                ...state,
                totalCount: action.payload
            }
        }
        case SET_SHOW_TEST_EVENTS: {
            return {
                ...state,
                showTestEvents: action.payload
            }
        }
        case SET_INFORMATION_FEED_SEARCH_VALUE: {
            return {
                ...state,
                searchValue: action.payload
            }
        }
        default:
            return state
    }
}