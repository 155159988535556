import {ILog} from "./types.data";

export type TLogsReducer = {
    logsList: ILog[],
    logsListLoading: boolean
    totalCount: number
}

export const SET_LOGS_LIST = 'SET_LOGS_LIST'
export const SET_LOGS_LIST_LOADING = 'SET_LOGS_LIST_LOADING'
export const SET_LOGS_TOTAL_COUNT = 'SET_LOGS_TOTAL_COUNT'

export type SET_LOGS_LIST_ACTION = {
    type: typeof SET_LOGS_LIST,
    payload: ILog[]
}
export type SET_LOGS_LIST_LOADING_ACTION = {
    type: typeof SET_LOGS_LIST_LOADING,
    payload: boolean
}
export type SET_LOGS_TOTAL_COUNT_ACTION = {
    type: typeof SET_LOGS_TOTAL_COUNT,
    payload: number
}

export type LOGS_ACTIONS =
    | SET_LOGS_LIST_ACTION
    | SET_LOGS_LIST_LOADING_ACTION
    | SET_LOGS_TOTAL_COUNT_ACTION