import React, {FC, useEffect, useState} from "react";
import {IUserLoginProps} from "./types";
import {TRootState} from "../../redux/store";
import {connect} from "react-redux";
import logo_name from '../../static/images/logo_name.png'
import clsx from "clsx";
import {SimpleInput} from "../common/Fields/SimpleInput/SimpleInput";
import formatStringByPattern from "format-string-by-pattern";
import {filterNumbersFromString, filterPhone, formatPhoneByPattern} from "../../utils/inputHelpers";
import {SimpleButton} from "../common/Buttons/SimpleButton";
import {Checkbox} from "@material-ui/core";
import {requestLoginSms, requestSendSms} from "../../redux/reducers/auth/actions";
import {useKeyPress} from "../../hooks/useKeyPress";
import {Loader} from "../common/Loader/Loader";

const s = require('./style.module.scss')

const UserLogin: FC<IUserLoginProps> = ({
                                            requestLoginSms,
                                            requestSendSms,
                                            loginLoading,
                                        }) => {
    const [activeStep1, setActiveStep1] = useState<boolean>(true)
    const [activeStep2, setActiveStep2] = useState<boolean>(false)

    const [phone, setPhone] = useState<string>('')
    const [key, setKey] = useState<string>('')
    const [rememberMe, setRememberMe] = useState<boolean>(true)

    const enterPress = useKeyPress('Enter')

    useEffect(() => {
        if (enterPress) {
            activeStep1 && requestSendSms({
                    username: filterPhone(phone)
                },
                () => {
                    setActiveStep1(false)
                    setActiveStep2(true)
                })

            activeStep2 && requestLoginSms({
                username: filterPhone(phone),
                password: key
            })
        }
    }, [enterPress])
    return (
        <div className={s.content}>
            <div className={s.centred}>
                <div className={s.logo}>
                    <img src={logo_name}/>
                </div>
                <div className={s.form}>

                    <div className={s.stepper}>
                        <div className={clsx(s.stepper_item, activeStep1 && s.stepper_item_active)}>
                            <div className={s.stepper_item_icon}>1</div>
                            <div className={s.stepper_item_title}>Ввод номера телефона</div>
                        </div>
                        <div className={clsx(s.stepper_item, activeStep2 && s.stepper_item_active)}>
                            <div className={s.stepper_line}></div>
                            <div className={s.stepper_item_icon}>2</div>
                            <div className={s.stepper_item_title}>Подтверждение</div>
                        </div>
                    </div>

                    {activeStep1 && <>
                        <SimpleInput value={phone}
                                     onChange={e => setPhone(formatPhoneByPattern(e.target.value))}
                                     label={'Номер телефона'}
                                     placeholder={'+7 (000) 000-00-00'}
                                     type={'phone'}
                        />

                        <SimpleButton
                            onClick={() => requestSendSms({
                                    username: filterPhone(phone)
                                },
                                () => {
                                    setActiveStep1(false)
                                    setActiveStep2(true)
                                })
                            }
                            style={{marginTop: 16}}
                            disabled={loginLoading}
                        >
                            Отправить код подтверждения
                        </SimpleButton>
                    </>}

                    {activeStep2 && <>
                        <SimpleInput value={key}
                                     onChange={e => setKey(formatStringByPattern('999999', filterNumbersFromString(e.target.value)))}
                                     label={'Код подтверждения'}
                                     placeholder={'000000'}
                        />

                        <div>
                            <Checkbox value={rememberMe}
                                      checked={rememberMe}
                                      onChange={() => setRememberMe(!rememberMe)}
                            />
                            <span>Запомнить меня на этом устройстве</span>
                        </div>

                        <div className={s.row}>
                            <SimpleButton color={"default"}
                                          onClick={() => {
                                              setActiveStep2(false)
                                              setActiveStep1(true)
                                          }}>Шаг назад</SimpleButton>
                            <SimpleButton
                                onClick={() => requestLoginSms({
                                    username: filterPhone(phone),
                                    password: key
                                })}
                                disabled={loginLoading}>
                                Войти
                            </SimpleButton>
                        </div>
                    </>}
                </div>
            </div>
            {loginLoading && <Loader fullScreen/>}
        </div>
    )
}

const mapStateToProps = (state: TRootState) => ({
    loginLoading: state.auth.loadings.loginLoading
})
const mapDispatchToProps = {
    requestSendSms,
    requestLoginSms
}

export const UserLoginContainer = connect(mapStateToProps, mapDispatchToProps)(UserLogin)