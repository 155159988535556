import {ISelLogs} from "./types";
import {setLogsList, setLogsListLoading, setLogsTotalCount} from "./actions";
import {instance} from "../../../api";
import {checkError} from "../../../../utils/helpers";

export const selLogsAsync: ISelLogs = payload => async (dispatch: any) => {
    await dispatch(setLogsListLoading(true))
    await instance().close().selLogs(payload)
        .then(res => {
            dispatch(setLogsList(res.data?.log || []))
            dispatch(setLogsTotalCount(res.data?.totalcount || 0))
        })
        .catch(err => {
            checkError(err)
        })
        .finally(() => {
            dispatch(setLogsListLoading(false))
        })
}