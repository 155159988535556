import {ISetUserInfo, ISetUserInfoLoading} from "./types";
import {SET_USER_INFO, SET_USER_INFO_LOADING} from "../types";

export const setUserInfo: ISetUserInfo = payload => ({
    type: SET_USER_INFO,
    payload
})

export const setUserInfoLoading: ISetUserInfoLoading = payload => ({
    type: SET_USER_INFO_LOADING,
    payload
})