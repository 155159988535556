import React from "react";
import {TRootState} from "../../../../redux/store";
import {connect} from "react-redux";
import {IGateway} from "../../../../redux/reducers/userPanel/remoteControllers/types.data";
import {Fade} from "@material-ui/core";

const s = require('./style.module.scss')

interface IGatewayListProps {
    gatewayList: IGateway[]
}

const GatewayList: React.FC<IGatewayListProps> = ({
                                                      gatewayList,
                                                  }) => {
    return (
        <table className={s.gateway_table}>
            <thead>
            <th>Зона</th>
            <th>Описание</th>
            </thead>
            <tbody>
            {gatewayList && gatewayList.length > 0 && gatewayList.map((gateway, gatewayIndex) => {
                return <Fade in timeout={{enter: 50 & gatewayIndex}}>
                    <tr style={{background: gatewayIndex % 2 === 1 ? 'rgba(38,38,38,.05)' : '#fff'}}>
                        <td>{gateway?.number}</td>
                        <td>{gateway?.desc}</td>
                    </tr>
                </Fade>
            })}
            </tbody>
        </table>
    )
}

const mapStateToProps = (state: TRootState) => ({
    gatewayList: state.remoteController.controllerGatewaysList,
})
export const GatewayListContainer = connect(mapStateToProps, {})(GatewayList)