import {ILoginSms, IRequestLogin, ISendSms} from "./types";
import {setIsAuth, setLoginLoading} from "./actions";
import {instance} from "../../../api";
import {keys} from "../../../api/keys";
import {ROUTE_PATHS} from "../../../../routers/paths.main";
import {checkError} from "../../../../utils/helpers";

export const requestLoginAsync: IRequestLogin = body => async (dispatch: any) => {
    await dispatch(setLoginLoading(true))
    await instance().open().authorize(body)
        .then(res => {
            const data = res.data;
            const {access_token, access_token_refresh} = data
            const main = keys.main
            const refresh = keys.refresh

            localStorage.setItem(main, access_token)
            localStorage.setItem(refresh, access_token_refresh)

            dispatch(setIsAuth(true))
            window.location.pathname = ROUTE_PATHS.admin.users(1).path
        })
        .catch(err => {
            dispatch(setIsAuth(false))
            checkError(err)

        })
        .finally(() => {
            dispatch(setLoginLoading(false))
        })
}

export const requestSendSms: ISendSms = (body, onSuccess) => async (dispatch: any) => {
    await dispatch(setLoginLoading(true))
    await instance().open().sendSms(body)
        .then(res => {
            onSuccess && onSuccess()
        })
        .catch(err => {
            checkError(err)
        })
        .finally(() => {
            dispatch(setLoginLoading(false))
        })
}

export const requestLoginSms: ILoginSms = body => async (dispatch: any) => {
    await dispatch(setLoginLoading(true))
    await instance().open().loginSms(body)
        .then(res => {
            const data = res.data;
            const {access_token, access_token_refresh} = data
            const main = keys.main
            const refresh = keys.refresh

            localStorage.setItem(main, access_token)
            localStorage.setItem(refresh, access_token_refresh)

            dispatch(setIsAuth(true))
            window.location.pathname = ROUTE_PATHS.main(1).path
        })
        .catch(err => {
            checkError(err)
        })
        .finally(() => {
            dispatch(setLoginLoading(false))
        })
}