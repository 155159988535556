import { TResponsibilityReducer } from './reducers/responsibility/types';
import {applyMiddleware, combineReducers, createStore} from "redux";
import {composeWithDevTools} from "redux-devtools-extension";
import thunk from 'redux-thunk'
import {TAuthReducer} from "./reducers/auth/types";
import {authReducer} from "./reducers/auth";
import {launchReducer} from "./reducers/launch";
import {TLaunchReducer} from "./reducers/launch/types";
import {sidebarReducer} from "./reducers/sidebar";
import {TSidebarReducer} from "./reducers/sidebar/types";
import {userReducer} from "./reducers/user";
import {TUserReducer} from "./reducers/user/types";
import {reportsReducer} from "./reducers/reports";
import {TReportsReducer} from "./reducers/reports/types";
import {remoteControllerReducer} from "./reducers/userPanel/remoteControllers";
import {TRemoteControllersReducer} from "./reducers/userPanel/remoteControllers/types";
import { userListReducer } from "./reducers/adminUsers";
import { TUserListReducer } from "./reducers/adminUsers/types";
import {informationFeedReducer} from "./reducers/userPanel/informationFeed";
import {TInformationFeedReducer} from "./reducers/userPanel/informationFeed/types";
import { TNumCodeReducer } from "./reducers/numCodes/types";
import { codesReducer } from "./reducers/numCodes";
import { responsibilityListReducer } from './reducers/responsibility';
import {TLogsReducer} from "./reducers/logs/types";
import {logsReducer} from "./reducers/logs";

const combinedReducers = combineReducers({
    auth: authReducer,
    launch: launchReducer,
    user: userReducer,
    sidebar: sidebarReducer,
    reports: reportsReducer,
    codes: codesReducer,
    remoteController: remoteControllerReducer,
    informationFeed: informationFeedReducer,
    adminUsers: userListReducer,
    responsibility: responsibilityListReducer,
    logs: logsReducer
})

const store = createStore(combinedReducers,
    composeWithDevTools(applyMiddleware(thunk)))

export type TRootState = {
    auth: TAuthReducer
    launch: TLaunchReducer
    user: TUserReducer
    sidebar: TSidebarReducer
    reports:  TReportsReducer
    codes: TNumCodeReducer
    remoteController: TRemoteControllersReducer
    informationFeed: TInformationFeedReducer
    adminUsers: TUserListReducer
    responsibility: TResponsibilityReducer
    logs: TLogsReducer
}

export {store}