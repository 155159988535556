import React from 'react';
import { IUser } from "../../../redux/reducers/adminUsers/types.data";
import { Fade } from "@material-ui/core";
import { SimpleButton } from "../../common/Buttons/SimpleButton"
import { Link } from 'react-router-dom';
import { ROUTE_PATHS } from "../../../routers/paths.main";
import { ISetSelectedUser } from "../../../redux/reducers/adminUsers/actions/types";
import { formatPhoneByPattern } from "../../../utils/inputHelpers";
import SettingsIcon from '../../../icons/SettingsIcon';

const s = require('./style.module.scss')

interface IUserListItemProps {
    index: number
    userListItem: IUser
    setSelectedUser: ISetSelectedUser

    [key: string]: any
}

export const UserListItem: React.FC<IUserListItemProps> = ({
    index,
    userListItem,
    setSelectedUser,
    ...props
}) => {

    const { id, name, phone } = userListItem
    return (
        <Fade in timeout={{ enter: 100 * index }}>
            <div className={s.row_component}>
                <div className={s.userListItem}>{name}</div>
                <div className={s.userListItem}>{phone && formatPhoneByPattern(phone)}</div>
                <div className={s.userListItem}>
                    <div className={s.search_field_admin}>
                        <Link to={{
                            pathname: ROUTE_PATHS.admin.user(id).path,
                            state: {
                                name,
                                phone,
                            }
                        }}>
                            <SimpleButton className={s.search_field_clear} size={window.innerWidth > 760 ? 'medium' : 'small'} onClick={() => {
                                setSelectedUser(userListItem)
                            }}>
                                {window?.innerWidth > 760 ? 'Управление пользователем' : <SettingsIcon color={'white'} />}
                            </SimpleButton>
                        </Link>
                    </div>
                </div>
            </div>
        </Fade>
    )
}