import {ISetLogsList, ISetLogsListLoading, ISetLogsTotalCount} from "./types";
import {SET_LOGS_LIST, SET_LOGS_LIST_LOADING, SET_LOGS_TOTAL_COUNT} from "../types";

export const setLogsList: ISetLogsList = payload => ({
    type: SET_LOGS_LIST,
    payload
})
export const setLogsListLoading: ISetLogsListLoading = payload => ({
    type: SET_LOGS_LIST_LOADING,
    payload
})
export const setLogsTotalCount: ISetLogsTotalCount = payload => ({
    type: SET_LOGS_TOTAL_COUNT,
    payload
})