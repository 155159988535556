export function formatISOToDateString (date: string) {
    let _date = date
        .slice(0, 10)
        .split('-').reverse().join('.');
    return  _date
}
export function formatISOToTimeString (date: string) {
    let _time = date.
    slice(date.indexOf('T')+1);
    return _time
}