import { useEffect } from "react";

export function useOnClickOutside(ref: any, handler: Function, stopHandler?: Function) {
    useEffect(
        () => {
            const listener = (event: any) => {
                if (!ref.current
                    || ref.current.contains(event.target)
                    || stopHandler && stopHandler(event)) {
                    return;
                }
                handler(event);
            };

            document.addEventListener('mousedown', listener);
            document.addEventListener('touchstart', listener);

            return () => {
                document.removeEventListener('mousedown', listener);
                document.removeEventListener('touchstart', listener);
            };
        },
        [ref, handler]
    );
}