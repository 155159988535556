import React from "react";
import clsx from "clsx";

const s = require('./style.module.scss')

interface ICreateCardFieldProps {
    label: string

    [key: string]: any
}

export const CreateCardField: React.FC<ICreateCardFieldProps> = ({
                                                                     label,
                                                                     children,
                                                                     ...props
                                                                 }) => {
    return (
        <div className={s.card_field}>
            <div className={s.card_field_label}>{label}</div>
            <div className={clsx(s.card_field_value, props?.className)}>
                {children}
            </div>
        </div>
    )
}