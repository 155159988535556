import {
    SET_INFO_FEED_LIST,
    SET_INFO_FEED_LIST_LOADING,
    SET_INFORMATION_FEED_SEARCH_VALUE,
    SET_SHOW_TEST_EVENTS, SET_TOTAL_INFO_FEED_COUNT
} from '../types';
import {
    ISetInfoFeedList,
    ISetInfoFeedListLoading,
    ISetInformationFeedSearchValue,
    ISetShowTestEvents,
    ISetTotalInfoFeedCount
} from './types';

export const setInfoFeedList:ISetInfoFeedList = payload => ({
    type:SET_INFO_FEED_LIST,
    payload
})
export const setInfoFeedListLoading:ISetInfoFeedListLoading = payload => ({
    type:SET_INFO_FEED_LIST_LOADING,
    payload
})
export const setTotalInfoFeedCount: ISetTotalInfoFeedCount = payload => ({
    type: SET_TOTAL_INFO_FEED_COUNT,
    payload
})
export const setShowTestEvents: ISetShowTestEvents = payload => ({
    type: SET_SHOW_TEST_EVENTS,
    payload
})
export const setInformationFeedSearchValue: ISetInformationFeedSearchValue = payload => ({
    type: SET_INFORMATION_FEED_SEARCH_VALUE,
    payload
})