import {
    REPORTS_ACTIONS,
    SET_FILTER_OBJECT_LIST,
    SET_FILTER_OBJECT_LIST_LOADING,
    SET_REPORTS_LIST,
    SET_REPORTS_LIST_LOADING,
    SET_TOTAL_REPORTS_COUNT,
    TReportsReducer
} from "./types";


const init: TReportsReducer = {
    reportsList: [],
    reportsListLoading: false,
    totalCount: 0,

    filterObjectList: [],
    filterObjectListLoading: false
}

export const reportsReducer = (state = init, action: REPORTS_ACTIONS) => {
    switch (action.type) {
        case SET_REPORTS_LIST: {
            return {
                ...state,
                reportsList: action.payload
            }
        }
        case SET_REPORTS_LIST_LOADING: {
            return {
                ...state,
                reportsListLoading: action.payload
            }
        }
        case SET_TOTAL_REPORTS_COUNT: {
            return {
                ...state,
                totalCount: action.payload
            }
        }
        case SET_FILTER_OBJECT_LIST: {
            return {
                ...state,
                filterObjectList: action.payload
            }
        }
        case SET_FILTER_OBJECT_LIST_LOADING: {
            return {
                ...state,
                filterObjectListLoading: action.payload
            }
        }
        default:
            return state
    }
}