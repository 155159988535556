import {instanceAxiosClose} from "../../instance.axios";
import {paths} from "../../paths";
import {TGetInformationFeedListBody} from "../userPanel/types.data";

export const reportsRequests = () => ({
    close: (token: string | null) => ({
        getReportsList: (body: TGetInformationFeedListBody) => {
            return instanceAxiosClose(token).post(paths.selEvents, body)
        },
        getFilterObjectsList: () => {
            return instanceAxiosClose(token).post(paths.selObj, {})
        }
    })
})