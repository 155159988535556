import React, { memo, useEffect } from "react";
import { ISideBarProps } from "./types";
import { TopSideContainer } from "./TopSide";
import { LeftSideContainer } from "./LeftSide";
import { TRootState } from "../../redux/store";
import { connect } from "react-redux";
import { setIsAdmin } from "../../redux/reducers/launch/actions/actions";
import { useLocation } from "react-router-dom";


const s = require('./style.module.scss')

const Sidebar: React.FC<ISideBarProps> = memo(({
    children,
    setIsAdmin,
}) => {
    const location = useLocation()
    useEffect(() => {
        setIsAdmin(!!location.pathname.includes('admin'))
    }, [location])

    const isMobile: boolean = window.innerWidth <= 768

    return (
        <div className={s.content_main}>
            <TopSideContainer isMobile={isMobile} />
            <div className={s.content}>
                {!isMobile && <LeftSideContainer />}

                {children}
            </div>
        </div>
    )
})

const mapStateToProps = (state: TRootState) => ({})
const mapDispatchToProps = {
    setIsAdmin
}

export const SidebarContainer = connect(mapStateToProps, mapDispatchToProps)(Sidebar)