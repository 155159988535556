import {IRequestReportsList} from "./types";
import {
    setFilterObjectList,
    setFilterObjectListLoading,
    setReportsList,
    setReportsListLoading,
    setTotalReportsCount
} from "./actions";
import {instance} from "../../../api";
import {checkError} from "../../../../utils/helpers";

export const getReportsListAsync: IRequestReportsList = (body) => async (dispatch: any) => {
    await dispatch(setReportsListLoading(true))
    await instance().close().getReportsList(body)
        .then(res => {
            dispatch(setReportsList(res.data?.events || []))
            dispatch(setTotalReportsCount(res.data?.totalcount || 0))
        })
        .catch(err => {
            checkError(err)
        })
        .finally(() => {
            dispatch(setReportsListLoading(false))
        })
}

export const getFilterObjectListAsync = () => async (dispatch: any) => {
    await dispatch(setFilterObjectListLoading(true))
    await instance().close().getFilterObjectsList()
        .then(res => {
            dispatch(setFilterObjectList(res.data || []))
        })
        .catch(err => {
            checkError(err)
        })
        .finally(() => {
            dispatch(setFilterObjectListLoading(false))
        })
}