import React, { Suspense, useEffect } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { TRootState } from "../redux/store";
import { connect } from "react-redux";
import { ROUTE_PATHS } from "./paths.main";
import { SidebarContainer } from "../components/Sidebar/Sidebar";
import { AdminSwitch } from "./adminSwitch/Switch";
import { UserPanelContainer } from "../components/UserPanel/UserPanel";
import { ReportsPageContainer } from "../components/Reports/ReportsPage";
import { Loader } from "../components/common/Loader/Loader";
import { AdminLoginContainer } from "../components/Login/AdminLogin";
import { ResponsibilityPageContainer } from "../components/Responsibility/ResponsibilityPage";
import { initAppAsync } from "../redux/reducers/launch/actions";
import { UserLoginContainer } from "../components/Login/UserLogin";
import { IUserInfo } from "../redux/reducers/user/types.data";
import { NotFoundPage } from "../components/NotFoundPage/NotFoundPage";


const s = require('./style.module.scss')

const RouterIndex: React.FC<{ appLoading: boolean, initAppAsync: any, userInfo: IUserInfo | null }> = ({
    appLoading,
    initAppAsync,
    userInfo,
}) => {
    useEffect(() => {
        initAppAsync()
    }, [])

    if (appLoading) {
        return <Loader fullScreen />
    }

    return (
        <BrowserRouter>
            <Suspense fallback={Loader}>
                <Switch>
                    <Route exact
                        path={'/'}
                        component={() => <Redirect to={userInfo?.isAdmin
                            ? ROUTE_PATHS.admin.path
                            : ROUTE_PATHS.main(1).path}
                        />}
                    />
                    <Route exact
                        path={ROUTE_PATHS.login}
                        component={UserLoginContainer} />
                    <Route exact
                        path={ROUTE_PATHS.admin.path}
                        component={AdminLoginContainer} />

                    <SidebarContainer>
                        {true && <Route exact
                            path={'/main/:page'}
                            component={UserPanelContainer} />}
                        {true && <Route exact
                            path={'/report/:page'}
                            component={() => <ReportsPageContainer isPage />} />}

                        {/*true && <Route exact
                            path={ROUTE_PATHS.workers}
                        component={() => <ResponsibilityPageContainer isPage />} />*/}

                        {true && <AdminSwitch />}

                    </SidebarContainer>
                </Switch>
            </Suspense>
        </BrowserRouter>
    )
}

const mapStateToProps = (state: TRootState) => ({
    appLoading: state.launch.appLoading,
    userInfo: state.user.userInfo,
})
const mapDispatchToProps = {
    initAppAsync,
}

export const RouterIndexContainer = connect(mapStateToProps, mapDispatchToProps)(RouterIndex)
