import React, {useState} from "react";
import {ILeftSideProps} from "./types";
import {TRootState} from "../../redux/store";
import {connect} from "react-redux";
import {setCurrentPage} from "../../redux/reducers/sidebar/actions/actions";
import clsx from "clsx";
import {Link} from "react-router-dom";
import {AnimatedItem} from "../common/AnimatedItem/AnimatedItem";
import {ArrowIcon} from "../../icons/icon";
import {FadeItem} from "../common/AnimatedItem/FadeAnim";
import {SimpleTooltip} from "../common/SimpleTooltip/SimpleTooltip";
import { isMobile } from "../../helpers/mobileHelpers";
import { SSF } from "xlsx/types";

const s = require('./style.module.scss')

const LeftSide: React.FC<ILeftSideProps> = ({
                                                internalPages,
                                                currentPage,
                                                setCurrentPage,
                                                openLeft,
                                                changeLeft
                                            }) => {
    const [open, setOpen] = useState<boolean>(false)

    return isMobile ? 
    <div className={clsx(openLeft && s.mobileNavbar)}>
        {internalPages.map(page => {
            return <Link to={page.url} className={s.mobileNavbar_item}
            onClick={()=>{changeLeft(false)}}
            
            >
                <SimpleTooltip active={!open}
                               title={page.name}
                               placement={"right"}>
                    {page.icon}
                </SimpleTooltip>
            <FadeItem active={openLeft || false}>
                {page.name}
            </FadeItem>
        </Link>
        })
    }
        

    </div>
    :
        <div className={clsx(s.sidebar, open && s.sidebar_open)}>
            {internalPages.map(page => {
                return <Link to={page.url} className={s.sidebar_item}>
                    <AnimatedItem>
                        <SimpleTooltip active={!open}
                                       title={page.name}
                                       placement={"right"}>
                            {page.icon}
                        </SimpleTooltip>
                    </AnimatedItem>
                    <FadeItem active={open}>
                        {page.name}
                    </FadeItem>
                </Link>
            })}
            <div className={clsx(s.sidebar_item,
                s.sidebar_item_arrow,
                open && s.sidebar_item_arrow_open)}
                 onClick={() => setOpen(!open)}>
                <AnimatedItem>
                    <SimpleTooltip active={!open}
                                   title={'Открыть меню'}
                                   placement={"right"}>
                        <ArrowIcon/>
                    </SimpleTooltip>
                </AnimatedItem>
                <FadeItem active={open}>Скрыть меню</FadeItem>
            </div>
        </div>
    
}

const mapStateToProps = (state: TRootState) => ({
    internalPages: state.user.userInfo?.isAdmin
        ? state.sidebar.adminPages
        : state.sidebar.clientPages,
    currentPage: state.sidebar.currentPage,
})
const mapDispatchToProps = {
    setCurrentPage
}

export const LeftSideContainer = connect(mapStateToProps, mapDispatchToProps)(LeftSide)