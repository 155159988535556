import { ICode } from "./type.data";


export type TNumCodeReducer = {
    codeList: ICode[]
    codeListLoading: boolean
    totalCount: number
}

export const SET_CODE_LIST = 'SET_CODE_LIST'
export const SET_CODE_LIST_LOADING = 'SET_CODE_LIST_LOADING'
export const SET_TOTAL_CODE_COUNT = 'SET_TOTAL_CODE_COUNT'

export type SET_CODE_LIST_ACTION = {
    type: typeof SET_CODE_LIST,
    payload: ICode[]
}
export type SET_CODE_LIST_LOADING_ACTION = {
    type: typeof SET_CODE_LIST_LOADING,
    payload: boolean
}

export type SET_TOTAL_CODE_COUNT_ACTION = {
    type: typeof SET_TOTAL_CODE_COUNT,
    payload: number
}

export type CODES_ACTIONS = 
    | SET_CODE_LIST_ACTION
    | SET_CODE_LIST_LOADING_ACTION
    | SET_TOTAL_CODE_COUNT_ACTION