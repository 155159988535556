import React from "react";
import {IRemoteController} from "../../../../redux/reducers/userPanel/remoteControllers/types.data";
import {CreateCardField} from "./common/CreateCardField";

const s = require('./style.module.scss')

interface IRemoteControllerHoverInfoProps {
    controller: IRemoteController
}

export const RemoteControllerHoverInfo: React.FC<IRemoteControllerHoverInfoProps> = ({
                                                                                         controller
                                                                                     }) => {
    const {
        connection, accountNumberSite, adressSite,
        aliasDevice, idDevice, idPart,
        status, nameDevice, namePart, test,
    } = controller

    return (
        <section className={s.body}>
            <CreateCardField label={'Псевдоним'}>
                <span>{aliasDevice}</span>
            </CreateCardField>

            <CreateCardField label={'Название'}>
                <span>{nameDevice}</span>
            </CreateCardField>

            <CreateCardField label={'Адрес'}>
                <span>{adressSite}</span>
            </CreateCardField>

            <CreateCardField label={'Пультовый номер'}>
                <span>{accountNumberSite}</span>
            </CreateCardField>

            <CreateCardField label={'Группа'}>
                <span>{namePart}</span>
            </CreateCardField>

            <CreateCardField label={'Статус'} className={s.white_text}>
                <span style={{background: `#${status?.color || "fff"}`}}>{status?.isStateArmSite || '-'}</span>
            </CreateCardField>

            <CreateCardField label={'Связь'} className={s.white_text}>
                <span style={{background: `#${connection?.gprsColor}`}}>{connection?.gprsText}</span>
                <span style={{background: `#${connection?.lanColor}`}}>{connection?.lanText}</span>
                {!connection?.gprsText && !connection?.lanText && <span>Нет</span>}
            </CreateCardField>

            {/*<CreateCardField label={'Внутренний тест'} className={s.white_text}>*/}
            {/*        <span style={{background: test ? '#00a338' : '#6c757d'}}>*/}
            {/*            {test*/}
            {/*                ? 'прошёл'*/}
            {/*                : 'не прошёл'}*/}
            {/*        </span>*/}
            {/*</CreateCardField>*/}

        </section>
    )
}
