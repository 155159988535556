import {LAUNCH_ACTIONS, SET_INIT_APP_LOADING, SET_IS_ADMIN, TLaunchReducer} from "./types";

const init: TLaunchReducer = {
    appLoading: false,
    isAdmin: false,
}

export const launchReducer = (state = init, action: LAUNCH_ACTIONS) => {
    switch (action.type) {
        case SET_INIT_APP_LOADING: {
            return {
                ...state,
                appLoading: action.payload
            }
        }
        case SET_IS_ADMIN: {
            return {
                ...state,
                isAdmin: action.payload
            }
        }
        default:
            return state
    }
}