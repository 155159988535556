import React from 'react';
import {IModalHeader} from "./types";
import {IconButton} from "@material-ui/core";
import {Close} from "@material-ui/icons";

const s = require('./style.module.scss')
export const ModalHeader: React.FC<IModalHeader> = ({
                                                        title,
                                                        closeHandler,
                                                    }) => {
    return (
        <div className={s.header}>
            <div className={s.header_title}>{title}</div>
            <IconButton onClick={closeHandler}>
                <Close/>
            </IconButton>
        </div>
    );
};

