import {
    TEditControllerAlias,
    TGetInformationFeedListBody,
    TGetRemoteControllerGatewaysBody,
    TGetRemoteControllersListBody
} from "./types.data";
import {instanceAxiosClose} from "../../instance.axios";
import {paths} from "../../paths";

export const userPanelRequests = () => ({
    open: () => ({}),
    close: (token: string | null) => ({
        getRemoteControllersList: (body: TGetRemoteControllersListBody) => {
            return instanceAxiosClose(token).post(paths.selSites, body)
        },
        getRemoteControllerGateways: (body: TGetRemoteControllerGatewaysBody) => {
            return instanceAxiosClose(token).post(paths.selZones, body)
        },
        getInformationFeedList: (body: TGetInformationFeedListBody) => {
            return instanceAxiosClose(token).post(paths.selEvents, body)
        },
        editControllerAlias:(body: TEditControllerAlias) => {
            return instanceAxiosClose(token).post(paths.iudSites, body)
        }
    })
})