import React from "react";

const s = require('./style.module.scss')

export const OneLoader: React.FC = ({}) => {
    return (
        <div className={s.loader}>
            <div className={s.loader_lines}>
                <div className={s.loader_item}/>
            </div>
        </div>
    )
}

