import React, {FC, memo, useState} from "react";
import {IRemoteControllerSmallProps} from "./types";
import {ControllerCardModalContainer} from "../Modals/ControllerCardModal";
import {Tooltip} from "antd";
import {RemoteControllerHoverInfo} from "../Modals/RemoteControllerHoverInfo";
import {ISetSelectedRemoteController} from "../../../../redux/reducers/userPanel/remoteControllers/actions/types";
import {IRemoteController} from "../../../../redux/reducers/userPanel/remoteControllers/types.data";

const s = require('./style.module.scss')

export const RemoteControllerSmall: React.FC<IRemoteControllerSmallProps> = memo(({
                                                                                 controllersGroup,
                                                                                 remoteControllersHeader,
                                                                                 setSelectedRemoteController,
                                                                             }) => {


    return (
        <div className={s.item_wrapper_small}>
            <div className={s.item_header}>

                {remoteControllersHeader === 'number' &&
                <div className={s.number}>{controllersGroup[0]?.accountNumberSite || "-"}</div>}
                {remoteControllersHeader === 'name' &&
                <div className={s.name}>{controllersGroup[0]?.nameDevice || "-"}</div>}

            </div>

            <div className={s.item_content_small}>
                {controllersGroup.length > 0
                    ? controllersGroup.map((value, index) => {
                        return <RemoteControllerSmallItem key={`${value.idDevice}-${index}`}
                                                          value={value}
                                                          setSelectedRemoteController={setSelectedRemoteController}
                        />
                    })
                    : <div>Нет объектов!</div>}
            </div>

        </div>
    )
})

interface IRemoteControllerSmallItem {
    setSelectedRemoteController: ISetSelectedRemoteController
    value: IRemoteController
}

const RemoteControllerSmallItem: FC<IRemoteControllerSmallItem> = memo(({
                                                                       setSelectedRemoteController,
                                                                       value,
                                                                   }) => {

    const [controllerCardModal, setControllerCardModal] = useState<boolean>(false)
    const [tooltipVisible, setTooltipVisible] = useState<boolean>(false)

    return (
        <Tooltip overlayInnerStyle={{width: '250px'}}
                 destroyTooltipOnHide
                 overlay={() => <RemoteControllerHoverInfo controller={value}/>}
                 visible={tooltipVisible}>

            <div style={{
                width: '100%',
                height: '100%',
                background: `#${value?.status.color || "3a3a3a"}`,
                color: value?.status?.color
                    ? '#fff'
                    : '#3a3a3a',
                border: value?.status?.color
                    ? 'none'
                    : '1px solid #3a3a3a'
            }}
                 className={s.item_content_small_div}
                 onClick={() => {
                     setSelectedRemoteController(value)
                     setControllerCardModal(true)
                 }}
                 onMouseOver={() => setTooltipVisible(true)}
                 onMouseLeave={() => setTooltipVisible(false)}>

                {value?.namePart || "-"}

            </div>
            <ControllerCardModalContainer open={controllerCardModal}
                                          closeHandler={() => setControllerCardModal(false)}
            />
        </Tooltip>
    )
})
