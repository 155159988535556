import {IUserInfo} from "./types.data";

export type TUserReducer = {
    userInfo: IUserInfo | null
    userInfoLoading: boolean
}

export const SET_USER_INFO = 'SET_USER_INFO'
export const SET_USER_INFO_LOADING = 'SET_USER_INFO_LOADING'

export type SET_USER_INFO_ACTION = {
    type: typeof SET_USER_INFO,
    payload: IUserInfo | null
}
export type SET_USER_INFO_LOADING_ACTION = {
    type: typeof SET_USER_INFO_LOADING,
    payload: boolean
}

export type USER_ACTIONS =
    | SET_USER_INFO_ACTION
    | SET_USER_INFO_LOADING_ACTION