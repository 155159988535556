import React from "react";
import {ICode} from "../../../redux/reducers/numCodes/type.data";
import {Fade, IconButton} from "@material-ui/core";
import {codeListFlexes} from "../../../helpers/flexesHelpers";
import DeleteIcon from '@material-ui/icons/Delete';
import {IiudVisibleCode} from "../../../redux/reducers/numCodes/action/types";

const s = require('./style.module.scss')

interface ICodeProps {
    code: ICode
    index: number
    iudVisibleCodeAsync: IiudVisibleCode

    [key: string]: any
}

export const Code: React.FC<ICodeProps> = ({
                                               code,
                                               index,
                                               iudVisibleCodeAsync,
                                               ...props
                                           }) => {
    return (
        <Fade in timeout={{enter: 50 * index}}>
            <div className={s.code_wrap}>
                <IconButton style={{padding: 2}} onClick={() => {
                    iudVisibleCodeAsync({
                        flag: "D",
                        code: code.code
                    })
                }}>
                    <DeleteIcon/>
                </IconButton>
                <div className={s.code_item} style={{flex: `0 0 ${codeListFlexes[0]}%`}}>{code?.code}</div>
            </div>

        </Fade>
    )
}