import React from "react";

export interface INavItem {
    id: number,
    name: string
    url: string
    icon: any
}

export const ADMIN_PAGES = {
    MAIN: 1,
    SETTINGS: 2,
    LOG: 3,
}
export const CLIENT_PAGES = {
    MAIN: 1,
    REPORTS: 2,
    USERS: 3,
}